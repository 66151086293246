import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import type { SidebarMenuItemProps } from '@uturn/ui';
import { Avatar, Logo } from '@uturn/ui';
import {
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
	Icon,
	ModeToggle,
} from '@uturn/ui-kit';
import { Suspense, useState } from 'react';
import { useAbac } from 'react-abac';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { GlobalSearch } from './global-search';
import ReportABug from './report-a-bug';
import type { Permission } from '@uturn/portal/abac';
import { Navigation } from '@uturn/portal/components/header/navigation';
import LanguageSelector from '@uturn/portal/components/language-selector';
import { isAfterDate } from '@uturn/portal/utils/is-date';

export function UserNav({
	dropdownItems,
	user,
}: {
	dropdownItems: { name: string; href: string }[];
	user: { name: string; role: string };
}) {
	const { t } = useTranslation();

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" className="relative size-8 rounded-full">
					<Avatar round size="2.3rem" name={user.name} />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-56" align="end" forceMount>
				<DropdownMenuLabel className="font-normal">
					<div className="flex flex-col space-y-1">
						<p className="text-sm font-medium leading-none">{user.name}</p>
						<p className="text-muted-foreground text-xs leading-none">
							{user.role}
						</p>
					</div>
				</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuGroup>
					{dropdownItems.map((item) => (
						<Link to={item.href} key={item.href}>
							<DropdownMenuItem className="cursor-pointer">
								{item.name}
							</DropdownMenuItem>
						</Link>
					))}
				</DropdownMenuGroup>
				<DropdownMenuSeparator />
				<ReportABug />
				<DropdownMenuSeparator />
				<Link to="/logout">
					<DropdownMenuItem className="cursor-pointer">
						{t('header.navigation.user.log-out', 'Log out')}
					</DropdownMenuItem>
				</Link>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}

export function Search() {
	const { t } = useTranslation();
	const [searchOpen, setSearchOpen] = useState(false);

	return (
		<>
			<Button
				variant="outline"
				className="text-muted-foreground justify-start bg-transparent text-sm"
				onClick={() => setSearchOpen(!searchOpen)}
			>
				<span className="hidden lg:block">
					{t('header.search.trigger-button', 'Search shipments')}
				</span>
				<Icon className="-mx-1 block lg:hidden" icon={faMagnifyingGlass} />
			</Button>

			<Suspense>
				<GlobalSearch
					openSearch={searchOpen}
					handleCloseSearch={() => setSearchOpen(!searchOpen)}
				/>
			</Suspense>
		</>
	);
}

export type NavigationItemProps = SidebarMenuItemProps & {
	permission?: Permission;
};

export function Header({
	userNavigation,
	dropdownItems,
	user,
}: {
	userNavigation: NavigationItemProps[];
	dropdownItems: { name: string; href: string }[];
	user: { name: string; role: string };
}) {
	const location = useLocation();
	const { userHasPermissions } = useAbac();

	// TODO: Improve this
	const activeNavigation = userNavigation
		.filter((item) => !item.permission || userHasPermissions(item.permission))
		.map((item) => {
			if (item.href === '/') {
				return {
					...item,
					active: location.pathname === '/',
				};
			}

			return {
				...item,
				active: location.pathname.includes(item.href.split('/')[1]),
			};
		});

	return (
		<div className="bg-card/95 supports-[backdrop-filter]:bg-card/60 sticky top-0 z-20 border-b backdrop-blur">
			<div className="3xl:w-2/3 3xl:px-0 m-auto flex h-16 items-center px-4 lg:px-8">
				<Link to="/shipments">
					<Logo
						variant={isAfterDate(2024, 11, 26) ? 'default' : 'christmas'}
						className={`${isAfterDate(2024, 11, 26) ? '-mt-1 h-8' : '-mt-3 h-12'} w-auto`}
					/>
				</Link>
				<div className="ml-6 flex w-full flex-row-reverse gap-4 md:flex-row md:gap-0">
					<Navigation navItems={activeNavigation} />
					<div className="ml-auto flex items-center gap-4 md:gap-1 lg:gap-4">
						<Search />
						<div className="hidden md:block">
							<ModeToggle />
						</div>
						<div className="hidden md:block">
							<LanguageSelector />
						</div>
						<UserNav dropdownItems={dropdownItems} user={user} />
					</div>
				</div>
			</div>
		</div>
	);
}
