import { useTranslation } from 'react-i18next';
import type { Column } from '@uturn/portal/hooks/shipments/use-column-defs';
import type { SearchShipment } from '@uturn/portal/modules/shipments/components/shipments-table/types';
import type { StatusTab } from '@uturn/portal/types/shipments';

type ViewColumns = Record<StatusTab, Column<SearchShipment>[]>;

function useLocationColumnGroup<T>() {
	const { t } = useTranslation();

	function getLocationColumnGroup(
		location: string,
		type: StatusTab,
		index: number,
	): Column<T> {
		return {
			headerName: t('shipments.table.group.header.location', {
				number: location.split('_')[1],
				defaultValue: 'Location {{ number }}',
			})!,
			groupId: location,
			children: [
				{
					headerName: t('shipments.table.header.location', {
						defaultValue: 'Location',
					})!,
					colId: `${location}.location.name`,
					hidden: !(index < 3), // Show location name for 2 first locations
				},
				{
					headerName: t('shipments.table.header.from', {
						defaultValue: 'From',
					})!,
					colId: `${location}.dateFrom`,
					hidden: !(index === 1) || type === 'assigned', // Show dateFrom for 2nd location, but not for assigned shipments
				},
				{
					headerName: t('shipments.table.header.until', {
						defaultValue: 'Until',
					})!,
					colId: `${location}.dateUntil`,
					hidden: !(index === 1), // Show dateUntil for 2nd location
				},
				{
					headerName: t('shipments.table.header.reference', {
						defaultValue: 'Reference',
					})!,
					colId: `${location}.reference`,
					hidden: true,
				},
				{
					headerName: t('shipments.table.header.remarks', {
						defaultValue: 'Remarks',
					})!,
					colId: `${location}.remarks`,
					hidden: true,
				},
				{
					headerName: t('shipments.table.header.customer', {
						defaultValue: 'Customer',
					})!,
					colId: `${location}.customerName`,
					hidden: true,
				},
				{
					headerName: t('shipments.table.header.shipping line', {
						defaultValue: 'Shipping line',
					})!,
					colId: `${location}.shippingLine.code`,
					hidden: true,
				},
				{
					headerName: t('shipments.table.header.vessel', {
						defaultValue: 'Vessel',
					})!,
					colId: `${location}.vesselName`,
					hidden: true,
				},
				{
					headerName: t('shipments.table.header.port', {
						defaultValue: 'Port',
					})!,
					colId: `${location}.port`,
					hidden: true,
				},
				{
					headerName: t('shipments.table.header.eta', { defaultValue: 'ETA' })!,
					colId: `${location}.eta`,
					hidden: !(index === 1) || type !== 'assigned', // Show ETA for 2nd location and only for assigned shipments
				},
				{
					headerName: t('shipments.table.header.arrival', {
						defaultValue: 'Arrival',
					})!,
					colId: `${location}.arrival`,
					hidden: true,
				},
				{
					headerName: t('shipments.table.header.departure', {
						defaultValue: 'Departure',
					})!,
					colId: `${location}.departure`,
					hidden: true,
				},
				{
					headerName: t('shipments.table.header.type', {
						defaultValue: 'Type',
					})!,
					colId: `${location}.locationActionType`,
					hidden: true,
				},
			],
		};
	}

	return {
		getLocationColumnGroup,
	};
}

const useShipmentDetailsColumnGroup = () => {
	const { t } = useTranslation();

	const getShipmentDetailsColumnGroup = (): Column<SearchShipment> => {
		const columns = {
			headerName: t('shipments.table.group.header.shipment_details', {
				defaultValue: 'Shipment Details',
			})!,
			groupId: 'shipmentDetails',
			children: [
				{
					colId: 'shipmentNumber',
					headerName: t('shipments.table.header.shipment_number', {
						defaultValue: 'Shipment Number',
					})!,
					hidden: false,
				}, // We always show shipmentNumber
				{
					colId: 'shipmentStatusFormatted',
					headerName: t('shipments.table.header.status', {
						defaultValue: 'Status',
					})!,
					hidden: true,
				},
				{
					colId: 'invoiceReference',
					headerName: t('shipments.table.header.invoice_reference', {
						defaultValue: 'Invoice reference',
					})!,
					hidden: true,
				},
				{
					colId: 'containerNumber',
					headerName: t('shipments.table.header.container_number', {
						defaultValue: 'Container number',
					})!,
					hidden: true,
				},
				{
					colId: 'containerType',
					headerName: t('shipments.table.header.container_type', {
						defaultValue: 'Container type',
					})!,
					hidden: true,
				},
				{
					colId: 'isoType',
					headerName: t('shipments.table.header.iso_code', {
						defaultValue: 'ISO code',
					})!,
					hidden: true,
				},
				{
					colId: 'seal',
					headerName: t('shipments.table.header.seal', {
						defaultValue: 'Seal',
					})!,
					hidden: true,
				},
				{
					colId: 'weight.quantity',
					headerName: t('shipments.table.header.cargo_gross_weight', {
						defaultValue: 'Cargo gross weight',
					})!,
					hidden: true,
				},
				{
					colId: 'cargoDescription',
					headerName: t('shipments.table.header.cargo_description', {
						defaultValue: 'Cargo description',
					})!,
					hidden: true,
				},
				{
					colId: 'requirements',
					headerName: t('shipments.table.header.requirements', {
						defaultValue: 'Requirements',
					})!,
					hidden: true,
				},
				{
					colId: 'carrierName',
					headerName: t('shipments.table.header.carrier', {
						defaultValue: 'Carrier',
					})!,
					hidden: true,
				},
				{
					colId: 'quoteCount',
					headerName: t('shipments.table.header.quotes', {
						defaultValue: 'Quotes',
					})!,
					hidden: true,
				},
				{
					colId: 'preferred_carriers',
					headerName: t('shipments.table.header.preferred_carriers', {
						defaultValue: 'Preferred carriers',
					})!,
					hidden: true,
				},
				{
					colId: 'publishUntil',
					headerName: t('shipments.table.header.publish_until', {
						defaultValue: 'Publish until',
					})!,
					hidden: true,
				},
				{
					colId: 'creator.name',
					headerName: t('shipments.table.header.created_by', {
						defaultValue: 'Created by',
					})!,
					hidden: true,
				},
				{
					colId: 'agreedPriceWithFee.quantity',
					headerName: t('shipments.table.header.agreed_price_with_fee', {
						defaultValue: 'Agreed total price',
					})!,
					hidden: true,
				},
				{
					colId: 'totalPrice.quantity',
					headerName: t('shipments.table.header.total_price', {
						defaultValue: 'Total price',
					})!,
					hidden: true,
				},
			],
		};

		return columns;
	};

	return {
		getShipmentDetailsColumnGroup,
	};
};

function determineDefaultColumns<T>(
	columnsToShow: (keyof T | string)[],
	type: StatusTab,
	locations?: string[],
): Column<T>[] {
	const { getShipmentDetailsColumnGroup } = useShipmentDetailsColumnGroup();
	const shipmentDetailsColumnGroup = getShipmentDetailsColumnGroup();

	const { getLocationColumnGroup } = useLocationColumnGroup<T>();

	return [
		{
			...shipmentDetailsColumnGroup,
			children: shipmentDetailsColumnGroup.children?.map((column) => ({
				...column,
				hidden: column.hidden && !columnsToShow.includes(column.colId),
			})),
		},
		...(locations?.map((location, i) =>
			getLocationColumnGroup(location, type, i),
		) ?? []),
	];
}

const defaultViewColumns: Record<string, string[]> = {
	published: [
		'invoiceReference',
		'containerNumber',
		'targetPrice.quantity',
		'totalPrice.quantity',
		'quoteCount',
	],
	assigned: [
		'invoiceReference',
		'containerNumber',
		'agreedPrice.quantity',
		'agreedPriceWithFee.quantity',
		'carrierName',
		'shipmentStatusFormatted',
	],
	drafted: [
		'invoiceReference',
		'containerNumber',
		'totalPrice.quantity',
		'targetPrice.quantity',
	],
	archived: [
		'invoiceReference',
		'containerNumber',
		'agreedPrice.quantity',
		'agreedPriceWithFee.quantity',
		'carrierName',
		'shipmentStatusFormatted',
	],
};

const getDefaultColumns = (
	type: StatusTab,
	locations?: string[],
): Column<SearchShipment>[] => {
	const defaultColumns: ViewColumns = Object.keys(defaultViewColumns).reduce(
		(acc, key) => ({
			...acc,
			[key]: determineDefaultColumns<SearchShipment>(
				defaultViewColumns[key],
				type,
				locations,
			),
		}),
		{},
	);

	return defaultColumns[type];
};

interface SavedColumn {
	key: string;
	hidden: boolean;
}

interface SavedColumnGroups
	extends Record<string, Record<string, SavedColumn[]>> {}

const formatSavedColumns = (
	savedColumns: SavedColumn[],
	columnDefs: Column<SearchShipment>,
	type: StatusTab,
) => {
	const columns = savedColumns
		.filter((savedColumn) =>
			columnDefs.children?.some((column) => column.colId === savedColumn.key),
		)
		.map((savedColumn) => {
			const defaultColumnInfo = columnDefs.children?.find(
				(column) => column.colId === savedColumn.key,
			);

			return {
				...defaultColumnInfo,
				colId: savedColumn.key,
				hidden: savedColumn.hidden,
			};
		});

	const missingColumns =
		columnDefs.children
			?.filter(
				(column) =>
					!savedColumns.some((savedColumn) => savedColumn.key === column.colId),
			)
			.map((column) => ({
				...column,
				colId: column.colId,
				hidden: !defaultViewColumns[type]?.includes(column.colId),
			})) ?? [];

	// To preserve the order of the saved columnsm, we always add the missing columns at the end of the columns
	return [...columns, ...missingColumns];
};

/**
 * There is probably a better way to do this, but since this is a temporary solution
 * until the columns are returned from the API instead of localstorage
 */
const useColumns = (
	type: StatusTab,
	locations?: string[],
): Column<SearchShipment>[] => {
	const { getLocationColumnGroup } = useLocationColumnGroup<SearchShipment>();
	const { getShipmentDetailsColumnGroup } = useShipmentDetailsColumnGroup();
	const viewColumnsStorage = localStorage.getItem('viewColumns');
	const parsedColumns = viewColumnsStorage
		? (JSON.parse(viewColumnsStorage) as SavedColumnGroups)
		: null;

	// If there are no columns saved, we return the default columns
	if (!parsedColumns || !parsedColumns[type]) {
		return getDefaultColumns(type, locations);
	}

	/**
	 * Known issue
	 *
	 * When we add a new header, it wont be added here since the user has saved a view with the old headers
	 * We should probably check if there is a difference between the default columns and the saved view columns
	 * and add the missing headers with the correct hidden state and remove any headers that are not present anymore
	 *
	 * formatSavedColumns has a quickfix for this now, the state will still hold the removed tables but will be filtered out in the results
	 */
	const columns = Object.keys(parsedColumns[type]).map((key, i) => {
		if (key?.match('location_[0-9]*')) {
			const defaultColumnDefinition = getLocationColumnGroup(key, type, i);

			return {
				...defaultColumnDefinition,
				children: formatSavedColumns(
					parsedColumns[type][key],
					defaultColumnDefinition,
					type,
				),
			};
		}

		const shipmentDetailsColumnGroup = getShipmentDetailsColumnGroup();
		return {
			...shipmentDetailsColumnGroup,
			children: formatSavedColumns(
				parsedColumns[type][key],
				shipmentDetailsColumnGroup,
				type,
			),
		};
	});

	return columns;
};

export default useColumns;
