import {
	faChevronLeft,
	faChevronRight,
	faEllipsisVertical,
} from '@fortawesome/pro-regular-svg-icons';
import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import {
	ConditionType,
	CurrencyType,
	type FileRequest,
	type QuoteDTO,
	type ShipmentListDTO,
	type ShipmentLocationActionDto,
	WeightUnit,
	getGetEventsByShipmentNumberQueryKey,
	useAddDocumentToShipment,
	useGetDocumentsOfShipment,
	useGetInvoiceLinesForPartyByShipment,
} from '@uturn/api/v1';

import {
	type Carrier,
	ShipmentDtoTsoStatus as OrderStatus,
	type ReviewResponse,
	type ShipmentDto,
	ShipmentDtoShipmentStatus as ShipmentStatus,
	type UpdateShipmentV2,
	useFetchReview,
	useGetPendingChangeSet,
	useUpdateShipment,
	useUpdateStatus,
} from '@uturn/api/v2';
import { useFetchOrganizationById, useGetShipment } from '@uturn/api/v3';
import type { ButtonProps } from '@uturn/ui';
import { Grid, Icon } from '@uturn/ui';
import {
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
	Form,
	sonner,
} from '@uturn/ui-kit';
import type { AxiosError, AxiosResponse } from 'axios';
import { useContext, useEffect, useRef, useState } from 'react';
import { useAbac } from 'react-abac';
import { Helmet } from 'react-helmet-async';
import type { FieldErrors } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ShipmentDetailSkeletonLoader } from '../../components/shipment-details-skeleton';
import SummarySection from '../../components/summary-section';
import { SideBarActions } from './sidebar-actions';
import { Permission } from '@uturn/portal/abac';
import { Page } from '@uturn/portal/components/page';
import { MetaDataContext } from '@uturn/portal/context';
import {
	CancelOrUnpublishShipmentModal,
	CarrierReviewBanner,
	ChangeRequestBanner,
	ChangeRequestModal,
	CostDeclaredBanner,
	PublishDetailsSection,
	QuotesSheet,
	RouteSection,
	ShipmentDetailsSection,
	ShipmentMapSection,
} from '@uturn/portal/modules/shipments/components';
import {
	EventsShipmentHistorySheet,
	ShareShipmentSheet,
} from '@uturn/portal/modules/shipments/components/shipment-sheet';
import { useDetermineChangeRequest } from '@uturn/portal/modules/shipments/hooks';
import { ShipmentDetailContext } from '@uturn/portal/modules/shipments/providers/shipment-detail';
import { formSchema } from '@uturn/portal/modules/shipments/schema';
import type { FormValues } from '@uturn/portal/modules/shipments/schema';
import type { CopyOptions } from '@uturn/portal/modules/shipments/utils';

import {
	copyLocations,
	copyRequirements,
	flattenObject,
	fromApi,
	fromForm,
	fromZodLocations,
	getNextPrevShipments,
	getReadOnlyFieldsList,
	grossWeightUndefined,
	isFieldReadOnly,
	isShipmentArchived,
	isShipmentCancellable,
	isShipmentMatched,
	shouldUpdate,
} from '@uturn/portal/modules/shipments/utils';
import {
	useGrossWeightValidation,
	usePriceValidation,
	useShipperReferenceValidation,
} from '@uturn/portal/modules/shipments/validation';
import { useLocationDatesValidation } from '@uturn/portal/modules/shipments/validation/location-dates';
import { useAddShipmentStore } from '@uturn/portal/store/shipments/add-shipment';
import type { CarrierReview, Documents } from '@uturn/portal/types/shipment';
import { ContainerStatus, initDocuments } from '@uturn/portal/types/shipment';
import { formatPrice, getApiErrorMessage } from '@uturn/portal/utils';

export function ShipmentDetail() {
	const stickyRef = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	const { determineChangeRequest } = useDetermineChangeRequest();
	const { validateLocationDates } = useLocationDatesValidation();
	const { validateGrossWeight } = useGrossWeightValidation();
	const { validateShipperReference } = useShipperReferenceValidation();
	const { validatePrice } = usePriceValidation();
	const { t } = useTranslation();
	const { userHasPermissions } = useAbac();
	const { orgDefaultValues, setCopyData, setCurrencyCode } =
		useAddShipmentStore();
	const { metadata, shipmentMetaData, shippingRequirementExceptions } =
		useContext(MetaDataContext);
	const { data: organisation } = useFetchOrganizationById(
		Number(metadata?.user?.organisation?.id) ?? 0,
	);

	const [shipmentStatus, setShipmentStatus] = useState<ShipmentStatus>();
	const [shipmentTsoStatus, setShipmentTsoStatus] = useState<
		OrderStatus | undefined
	>(undefined);
	const [isGBRShipment, setIsGBRShipment] = useState(false);
	const [isCrossBorderShipment, setIsCrossBorderShipment] = useState(false);

	const [carrierReview, setCarrierReview] = useState<CarrierReview>(undefined);

	const [pendingChangeRequestActive, setPendingChangeRequestActive] =
		useState(false);
	const [pendingExtraCost, setPendingExtraCost] = useState(false);
	const [pendingCarrierReview, setPendingCarrierReview] = useState(false);

	// const [newPlotLocations, setNewPlotLocations] = useState<any[]>([]);
	// const [newLocationActions, setNewLocationActions] = useState<any[]>([]);
	const [oldLocationActions, setOldLocationActions] = useState<
		ShipmentLocationActionDto[]
	>([]);

	const [assignedCarrier, setAssignedCarrier] = useState<Carrier>();
	const [newFiles, setNewFiles] = useState<Documents>(initDocuments);

	const [enableShipmentNavigation, setEnableShipmentNavigation] =
		useState<boolean>(false);

	const nextShipment = useRef<ShipmentListDTO>();
	const prevShipment = useRef<ShipmentListDTO>();

	const [initData, setInitData] = useState<Partial<FormValues>>({});
	const [wasConcept, setWasConcept] = useState(false);

	const [primaryActions, setPrimaryActions] = useState<ButtonProps['dropdown']>(
		[],
	);
	const [secondaryActions, setSecondaryActions] = useState<
		ButtonProps['dropdown']
	>([]);
	const [quotes, setQuotes] = useState<QuoteDTO[]>();

	const [openChangeRequestModal, setOpenChangeRequestModal] = useState(false);
	const [openCancelShipmentModal, setOpenCancelShipmentModal] = useState(false);
	const promptChangeRequestModal = useRef<boolean>(true);

	const {
		readOnlyFields,
		setReadOnlyFields,
		setChangeRequestLocations,
		setChangeRequestChanges,
	} = useContext(ShipmentDetailContext);
	const [numberOfInvoicedLines, setNumberOfInvoicedLines] = useState<number>(0);

	const { state, key } = useLocation();

	const { id } = useParams();
	const {
		data: shipment,
		queryKey: shipmentQueryKey,
		isLoading: loadingShipment,
	} = useGetShipment(Number(id), {
		query: {
			refetchOnWindowFocus: false,
			// cacheTime: Infinity, // TODO: Test this new option.
			onError: (error: AxiosError | any) => {
				sonner.error(
					t(
						'pages.shipment-detail.api-error.get-shipment',
						'Could not load shipment. {{errorMessage}}',
						{ errorMessage: getApiErrorMessage(error) },
					),
				);
			},
		},
	});

	const [openShareSheet, setOpenShareSheet] = useState(false);
	const [openEventSheet, setOpenEventSheet] = useState(false);
	const [openQuoteSheet, setOpenQuoteSheet] = useState(false);

	const form = useForm<Partial<FormValues>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			containerType: '',
			publishUntilDate: '',
			equipment: {
				containerNumber: '',
				isoType: '',
				shipmentUnitId: 0,
			},
			grossWeight: {
				quantity: 0,
				unit: WeightUnit.kilogram,
			},
			price: {
				quantity: 0,
				currencyCode: CurrencyType.EUR,
			},
			unCode: '',
			dutiesPaid: undefined,
			generatorSet: false,
			fixedPrice: true,
			conditions: undefined,
			shipperReference: '',
			preferredCarrierIds: [],
			numberOfCopiesToBeCreated: 1,
			containerStatus: undefined, // ''
			seal: '',
			details: '',
			locations: [],
		},
		mode: 'onBlur',
	});

	const {
		queryKey: pendingChangeSetQueryKey,
		refetch: refetchPendingChangeSet,
	} = useGetPendingChangeSet(Number(id), {
		query: {
			enabled: false,
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				const values = fromApi(data.data);
				form.reset(values, { keepDefaultValues: true });
			},
			onError: (error: AxiosError | any) => {
				sonner.error(
					t(
						'pages.shipment-detail.api-error.get-pending-change-set',
						'Could not load pending change requests. {{errorMessage}}',
						{ errorMessage: getApiErrorMessage(error) },
					),
				);
			},
		},
	});

	const {
		data: documents,
		queryKey: documentQueryKey,
		isLoading: loadingDocs,
	} = useGetDocumentsOfShipment(Number(id), {
		query: {
			suspense: false,
			onError: (error: AxiosError | any) => {
				sonner.error(
					t(
						'pages.shipment-detail.api-error.get-documents',
						'Could not load documents. {{errorMessage}}',
						{ errorMessage: getApiErrorMessage(error) },
					),
				);
			},
		},
	});

	const handleCarrierReviewUpdate = (review?: ReviewResponse) => {
		if (review) {
			setCarrierReview(review);
			setPendingCarrierReview(false);
			return;
		}
		setCarrierReview(undefined);
		setPendingCarrierReview(isShipmentArchived(shipmentStatus));
	};

	useFetchReview(Number(id), {
		query: {
			enabled: !!assignedCarrier,
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				const { data: review } = data;
				handleCarrierReviewUpdate(review);
			},
			onError: (error: AxiosError | any) => {
				handleCarrierReviewUpdate();
				sonner.error(
					t(
						'pages.shipment-detail.api-error.get-carrier-review',
						'Could not load carrier review. {{errorMessage}}',
						{ errorMessage: getApiErrorMessage(error) },
					),
				);
			},
		},
	});

	const { mutate: updateShipment, isLoading: updatingShipment } =
		useUpdateShipment();
	const { mutate: addDocument } = useAddDocumentToShipment();

	const { refetch } = useGetInvoiceLinesForPartyByShipment(Number(id), {
		query: {
			enabled: false,
			onSuccess: (data) => {
				const invoicedLines = data.data.lines?.filter((line) => {
					return line.lineStatus === 'Invoiced';
				});
				setNumberOfInvoicedLines(invoicedLines?.length ?? 0);
			},
			onError: (error: AxiosError | any) => {
				sonner.error(
					t(
						'pages.shipment-detail.api-error.get-invoice-lines',
						'Could not load invoice lines for party. {{errorMessage}}',
						{ errorMessage: getApiErrorMessage(error) },
					),
				);
			},
		},
	});

	const queryClient = useQueryClient();

	const setPublishStatus = useUpdateStatus({
		mutation: {
			onSuccess: () => {
				queryClient.invalidateQueries({ queryKey: shipmentQueryKey });
				queryClient.invalidateQueries({
					queryKey: getGetEventsByShipmentNumberQueryKey(Number(id)),
				});
				sonner.success(
					t(
						'pages.shipment-detail.api-success.update-status',
						'Shipment published successfully',
					),
				);
			},
			onError: (error: AxiosError | any) => {
				sonner.error(
					t(
						'pages.shipment-detail.api-error.update-status',
						'Could not publish shipment. {{errorMessage}}',
						{ errorMessage: getApiErrorMessage(error) },
					),
				);
			},
		},
	});

	const formRef = useRef<HTMLFormElement>(null);

	const watchPrice = form.watch('price.quantity');
	const watchPriceCurrencyCode = form.watch('price.currencyCode');
	const watchLocations = form.watch('locations');
	const watchIsNotPublishing = form.watch('concept');

	useEffect(() => {
		/**
		 * For now we just calculate the top position of the sticky bar once
		 * In the future if we want to support the height of the nav changing this will need to be updated
		 */
		if (stickyRef.current) {
			stickyRef.current.style.top = `${stickyRef.current
				.getBoundingClientRect()
				.top.toString()}px`;
		}
	}, [stickyRef]);

	useEffect(() => {
		if (organisation?.data.financialDetails) {
			// Pre-requisite for Financials component
			setCurrencyCode(organisation.data.financialDetails.currency);
		}
	}, [organisation]);

	useEffect(() => {
		if (!shipmentTsoStatus) return;

		if (shipmentTsoStatus === OrderStatus.INVOICED) {
			refetch();
		}
	}, [shipmentTsoStatus]);

	useEffect(() => {
		setReadOnlyFields(
			getReadOnlyFieldsList(
				shipmentStatus!,
				shipmentTsoStatus!,
				numberOfInvoicedLines,
			),
		);
	}, [shipmentStatus, numberOfInvoicedLines]);

	/**
	 * // TODO: Revisit the setIsCrossBorderShipment logic because and FR - UK shipment
	 *          is also cross-border, right?
	 *
	 * Currently we check both the 2 letter notation aswell as the 3 letter notation
	 * The reason for this is that the shipment endpoint which loads the initial data returns
	 * the 2 letter notation, but the autocomplete returns the 3 letter notation
	 *
	 * This counts for both the hasCrossBorderCountry and isGBRShipment check
	 *
	 */
	const setApplicableConditions = (values: Partial<FormValues>) => {
		const { countryCodes } = fromZodLocations(values.locations);

		setIsCrossBorderShipment(
			countryCodes.length > 0 &&
				countryCodes.some((country) => country !== 'NLD' && country !== 'NL'),
		);
		setIsGBRShipment(
			countryCodes.length > 0 &&
				countryCodes.every((country) => country === 'GBR' || country === 'GB'),
		);
	};

	const setInitValues = (data: ShipmentDto) => {
		const values = fromApi(data);

		form.reset(values);

		setWasConcept(data.shipmentStatus === ShipmentStatus.CONCEPT);
		setInitData({ ...values });

		setPendingChangeRequestActive(data.pendingChangeRequest!);
		if (data.pendingChangeRequest) {
			refetchPendingChangeSet();
		}

		setAssignedCarrier(data.carrier);
		if (!data.carrier) {
			handleCarrierReviewUpdate();
		}

		setOldLocationActions(data.locationActions ?? []);
		// setNewLocationActions(data.locationActions ?? []);

		setShipmentTsoStatus(data.tsoStatus);
		if (data.tsoStatus) {
			setPendingExtraCost(data.tsoStatus === OrderStatus.COST_DECLARED);
		} else {
			setPendingExtraCost(false);
		}

		setShipmentStatus(data.shipmentStatus);
		setQuotes(data.quotes ?? []);

		setApplicableConditions(values);
	};

	const submitDocument = async (file: File, fileRequest: FileRequest) => {
		const fileUploadType = shipmentMetaData!.fileTypes!.find(
			(type: any) => type.code === fileRequest.fileType,
		);

		const properties = {
			description: fileRequest.description,
			fileName: fileRequest.fileName,
			type: fileUploadType,
		};

		addDocument(
			{
				shipmentNumber: Number(id),
				data: {
					file,
					properties,
				},
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries({ queryKey: documentQueryKey });
					setNewFiles({ files: [], fileRequests: [] });
					sonner.success(
						t(
							'pages.shipment-detail.modal.add-document.submit.success.description',
							'Document uploaded successfully',
						),
					);
				},
				onError: (error: AxiosError | any) => {
					sonner.error(
						t(
							'pages.shipment-detail.modal.add-document.submit.error.description',
							'Upload failed. {{errorMessage}}',
							{ errorMessage: getApiErrorMessage(error) },
						),
					);
				},
			},
		);
	};

	const handleFormSubmit = () => {
		if (formRef.current === null) return;

		// const { locationActions } = fromZodLocations(
		// 	watchLocations as ZodLocation[]
		// );
		//
		// setNewLocationActions(locationActions);

		form.clearErrors();

		formRef.current.dispatchEvent(
			new Event('submit', { cancelable: true, bubbles: true }),
		);
	};

	const handlePublish = () => {
		if (!userHasPermissions(Permission.PUBLISH_SHIPMENT)) {
			sonner.error(
				t(
					'pages.shipment-detail.sticky-bar-actions-denied.publish_shipment_button.title',
					'Waiting for approval',
				),
				{
					description: t(
						'pages.shipment-detail.sticky-bar-actions-denied.publish_shipment_button.description',
						'Cannot publish your shipment while your organisation is pending approval by UTURN.',
					),
				},
			);
			return;
		}
		form.setValue('concept', false, shouldUpdate);
		handleFormSubmit();
	};

	const isFormValid = (data: FormValues, publishing: boolean) => {
		form.clearErrors();

		let results = validateGrossWeight(
			data.grossWeight?.quantity ?? 0,
			data.containerStatus ?? ContainerStatus.FULL,
			publishing,
		);

		results = validateLocationDates(
			data.locationActions,
			data.publishUntilDate ?? '',
			publishing,
			true,
			results,
		);

		results = validatePrice(data.price?.quantity ?? 0, publishing, results);

		results = validateShipperReference(
			data.shipperReference ?? '',
			data.invoiceRequired ?? true,
			publishing,
			results,
		);

		if (!results.valid) {
			for (let i = 0; i < results.messages.length; i += 1) {
				const message = results.messages[i];
				const path = results.paths[i];
				form.setError(path, {
					type: 'custom',
					message,
				});
			}
			form.setFocus(results.paths[0], { shouldSelect: true });
			return false;
		}

		return true;
	};

	const sendData = async (data: UpdateShipmentV2, publishing: boolean) => {
		if (updatingShipment) return;

		updateShipment(
			{
				id: Number(id),
				data,
			},
			{
				onSuccess: () => {
					if (publishing) {
						setPublishStatus.mutate({ shipmentId: Number(id) });
					} else {
						queryClient.invalidateQueries({ queryKey: shipmentQueryKey });
						queryClient.invalidateQueries({
							queryKey: pendingChangeSetQueryKey,
						});
						queryClient.invalidateQueries({
							queryKey: getGetEventsByShipmentNumberQueryKey(Number(id)),
						});
						sonner.success(
							t(
								'pages.shipment-detail.form.submit.success.description',
								'Shipment updated successfully',
							),
						);
					}
				},
				onError: (error: AxiosError | any) => {
					if (publishing) {
						sonner.error(
							t(
								'pages.shipment-detail.form.publish.error.description',
								'Publish failed. {{ errorMessage }}',
								{ errorMessage: getApiErrorMessage(error) },
							),
						);
					} else {
						sonner.error(
							t(
								'pages.shipment-detail.form.update.error.description',
								'Update failed. {{ errorMessage }}',
								{ errorMessage: getApiErrorMessage(error) },
							),
						);
					}
				},
			},
		);
	};

	const onSubmit = async (data: FormValues) => {
		/**
		 * Publishing or updating published shipment
		 * NOTE: see fromApi for concept initialization.
		 */
		const publishing = wasConcept && !(data.concept ?? true);

		if (!isFormValid(data, publishing)) {
			return;
		}

		const putData = fromForm(data, Number(id)) as UpdateShipmentV2;

		if (isShipmentMatched(shipmentStatus)) {
			const { locationActions: newLocationActions } = fromZodLocations(
				data.locations,
			);
			const changesRequests = determineChangeRequest({
				oldValues: fromForm(initData, Number(id)) as UpdateShipmentV2,
				newValues: putData,
				oldLocationActions: structuredClone(oldLocationActions),
				newLocationActions: structuredClone(newLocationActions),
				dirtyFieldRefs: flattenObject(form.formState.dirtyFields),
			});
			if (changesRequests.length > 0 && promptChangeRequestModal.current) {
				setChangeRequestLocations({
					currentLocations: oldLocationActions,
					newLocations: newLocationActions,
				});
				setChangeRequestChanges(changesRequests);
				setOpenChangeRequestModal(true);
			} else {
				promptChangeRequestModal.current = true;
				const { publishUntilDate, ...withoutPublishUntil } = putData;
				sendData(withoutPublishUntil, publishing);
			}
		} else {
			sendData(putData, publishing);
		}
	};

	const onError = (errors: FieldErrors<FormValues>) => {
		if (import.meta.env.DEV) {
			// eslint-disable-next-line no-console
			console.log('ShipmentDetail.onError', {
				errors,
			});
		}
	};

	const handleShipmentNavigation = (shipmentNumber: number | undefined) => {
		if (!shipmentNumber) return;

		navigate(`/shipments/${shipmentNumber}`, {
			state: {
				parentQueryKey: state?.parentQueryKey,
				parentLocation: state?.parentLocation,
			},
		});
	};

	/**
	 * Handles the logic of copying a shipment.
	 *
	 * @logic
	 * For a completed shipment, it doesn't make sense to copy over the container
	 * details and past shipment dates, since they can't be re-used for a new shipment.
	 *
	 * Container details to filter out are:
	 * - Container number
	 * - Tare weight
	 * - Cargo Gross weight
	 * - Seal
	 *
	 * For completed or cancelled shipments:
	 * - Any dates < current time
	 *
	 * Filter out carrier execution details, i.e.:
	 * - arrival
	 * - departure
	 * - eta
	 *
	 * Filter out BE-only managed shipping requirement codes, i.e.:
	 * - ADR
	 * - GENSET
	 *
	 * Others ignored fields during copy:
	 * - Preferred carriers
	 *
	 */
	const handleCopyShipment = () => {
		const options: CopyOptions = {
			shipmentRequirements: shipmentMetaData?.shipmentRequirements ?? [],
			shippingRequirementExceptions,
		};

		const data = form.getValues();
		const { preferredCarrierIds, newGroup, ...toCopyData } = data;

		setCopyData({
			...toCopyData,
			equipment: { ...data?.equipment, containerNumber: '' },
			tareWeight: { ...data?.tareWeight, quantity: 0 },
			grossWeight: { ...data?.grossWeight, quantity: grossWeightUndefined },
			seal: '',
			locations: copyLocations(data),
			requirementIds: copyRequirements(data, options),
			numberOfCopiesToBeCreated: 1,
		});

		navigate(`/shipments/add/manual`);
	};

	/**
	 * Handles the logic of re-publishing a shipment.
	 *
	 * @logic
	 * When a shipment is cancelled, it is an exception. Meaning that
	 * the transport wasn't executed. So it could be, that the same
	 * shipment still needs to be transported and therefore, you want
	 * to keep the execution details.
	 *
	 * toRepublish is based on toCopy, except it keeps the container
	 * details instead of filtering them out.
	 */
	const handleRepublishShipment = () => {
		const options: CopyOptions = {
			shipmentRequirements: shipmentMetaData?.shipmentRequirements ?? [],
			shippingRequirementExceptions,
		};

		const data = form.getValues();
		const { preferredCarrierIds, newGroup, ...toRepublishData } = data;

		setCopyData({
			...toRepublishData,
			locations: copyLocations(data),
			requirementIds: copyRequirements(data, options),
			numberOfCopiesToBeCreated: 1,
		});

		navigate(`/shipments/add/manual`);
	};

	useEffect(() => {
		const subscription = form.watch((value, { name, type }) => {
			if (name?.match(/^locations\.[0-9]+\.countryCode/)) {
				setApplicableConditions(value);
			}
		});

		return () => subscription.unsubscribe();
	}, [form.watch]);

	useEffect(() => {
		// const { conditions: defaultConditions } = orgDefaultValues ?? {};
		switch (true) {
			// case defaultConditions:
			// 	form.setValue('conditions', defaultConditions, shouldUpdate);
			// 	break;
			case isGBRShipment:
				form.setValue('conditions', ConditionType.RHA, shouldUpdate);
				break;
			case isCrossBorderShipment:
				form.setValue('conditions', ConditionType.CMR, shouldUpdate);
				break;
			default:
				form.setValue('conditions', ConditionType.AVC, shouldUpdate);
				break;
		}
	}, [isCrossBorderShipment, isGBRShipment, orgDefaultValues]);

	useEffect(() => {
		if (newFiles.files.length < 1 && newFiles.fileRequests.length < 1) return;
		/**
		 * This is a hack that currently just gets the last element of the array
		 * since update works different than create, and update submits the moment you click save in the modal instead of submit
		 */

		submitDocument(
			newFiles.files[newFiles.files.length - 1],
			newFiles.fileRequests[newFiles.fileRequests.length - 1],
		);
	}, [newFiles]);

	useEffect(() => {
		if (!state?.parentQueryKey) return;
		setEnableShipmentNavigation(true);
	}, [state]);

	useEffect(() => {
		if (!key) return;
		if (!state?.parentQueryKey) return;
		const shipments = queryClient.getQueryData<{
			data: AxiosResponse<ShipmentListDTO[]>;
		}>(state.parentQueryKey);
		if (!shipment!.data || !state || !shipments) return;
		const { next, prev } = getNextPrevShipments({
			shipments: shipments.data.data,
			currentShipmentNumber: Number(id),
		});
		nextShipment.current = next;
		prevShipment.current = prev;
	}, [key, shipment]);

	useEffect(() => {
		const response = shipment?.data;

		if (!response) return;

		setInitValues(response);
	}, [shipment]);

	useEffect(() => {
		if (shipmentStatus) {
			const shipmentPrimaryActions: ButtonProps['dropdown'] = [];
			switch (shipmentStatus) {
				case ShipmentStatus.QUOTED:
					shipmentPrimaryActions.unshift({
						label: t(
							'pages.shipment-detail.actions.quoted',
							'Quotes ({{quotes}})',
							{
								quotes: quotes?.length ?? 0,
							},
						),
						handleOnClick: () => {
							setOpenQuoteSheet(true);
						},
					});
					break;
				case ShipmentStatus.CANCELLED:
					shipmentPrimaryActions.unshift({
						label: t(
							'pages.shipment-detail.more.actions.republish',
							'Republish shipment',
						),
						handleOnClick: handleRepublishShipment,
					});
					break;
				default:
					shipmentPrimaryActions.unshift({
						label: t(
							'pages.shipment-detail.more.actions.copy',
							'Copy shipment',
						),
						handleOnClick: handleCopyShipment,
					});
					break;
			}
			const shipmentSecondaryActions: ButtonProps['dropdown'] = [
				{
					label: t(
						'pages.shipment-detail.more.actions.print',
						'Print shipment',
					),
					handleOnClick: () => {
						window.print();
					},
				},
				{
					label: t(
						'pages.shipment-detail.more.actions.share',
						'Share shipment',
					),
					handleOnClick: () => {
						setOpenShareSheet(true);
					},
				},
			];
			if (shipmentStatus === ShipmentStatus.QUOTED) {
				shipmentSecondaryActions.unshift({
					label: t('pages.shipment-detail.more.actions.copy', 'Copy shipment'),
					handleOnClick: handleCopyShipment,
				});
			}
			if (shipmentStatus === ShipmentStatus.CONCEPT) {
				shipmentSecondaryActions.unshift({
					label: t(
						'pages.shipment-detail.more.actions.archive',
						'Archive shipment',
					),
					handleOnClick: () => {
						setOpenCancelShipmentModal(true);
					},
				});
			}
			shipmentSecondaryActions.unshift({
				label: t('pages.shipment-detail.actions.events', 'Events'),
				handleOnClick: () => {
					setOpenEventSheet(true);
				},
			});
			setPrimaryActions(shipmentPrimaryActions);
			setSecondaryActions(shipmentSecondaryActions);
		}
	}, [shipmentStatus, watchLocations]);

	if (loadingShipment || loadingDocs) {
		return (
			<ShipmentDetailSkeletonLoader
				caption={t('general.loading', 'Loading...')!}
			/>
		);
	}

	return (
		<>
			<Helmet
				title={
					t('pages.shipments.details.pagetitle', '# {{ ID }}', {
						ID: id,
					})!
				}
			/>
			<Page
				title={
					t('pages.shipments.details.title', 'Shipment ID - #{{ ID }}', {
						ID: id,
					})!
				}
				backButton={{
					label: 'Shipments',
					href: '/shipments',
				}}
			>
				<Grid
					columns={[
						{
							type: '%',
							value: 60,
						},
						{
							type: 'auto',
						},
					]}
					className="relative min-h-screen pb-16 print:!flex print:flex-col"
					gap={2.5}
				>
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit, onError)} ref={formRef}>
							<div className="flex flex-col gap-4">
								{pendingChangeRequestActive && (
									<ChangeRequestBanner
										shipmentNumber={id ?? ''}
										shipmentQueryKey={shipmentQueryKey}
										pendingChangeSetQueryKey={pendingChangeSetQueryKey}
									/>
								)}
								{pendingExtraCost && (
									<CostDeclaredBanner shipmentNumber={String(id)} />
								)}
								{pendingCarrierReview && assignedCarrier && (
									<CarrierReviewBanner
										shipmentNumber={String(id)}
										carrier={assignedCarrier}
										carrierReview={carrierReview}
									/>
								)}
								<ShipmentDetailsSection
									shipmentNumber={Number(id)}
									shipmentStatus={shipmentStatus}
									tsoStatus={shipmentTsoStatus}
									carrier={assignedCarrier}
									carrierReview={carrierReview}
									containerTypes={shipmentMetaData?.shipmentUnits ?? []}
									shipmentRequirements={
										shipmentMetaData?.shipmentRequirements ?? []
									}
									shippingLines={shipmentMetaData?.shippingLines ?? []}
									initDocuments={documents?.data}
									setNewFiles={(files, fileRequests) => {
										setNewFiles({ files, fileRequests });
									}}
									documentQueryKey={documentQueryKey}
								/>
								<RouteSection
									shipmentNumber={Number(id)}
									shipmentStatus={shipmentStatus}
									transportType={initData?.transportType}
									isReadOnly={isFieldReadOnly(readOnlyFields, 'routeSection')}
								/>
								<PublishDetailsSection
									shipmentStatus={shipmentStatus}
									isCrossBorderShipment={isCrossBorderShipment}
									isGBRShipment={isGBRShipment}
								/>
							</div>
						</form>
						{import.meta.env.DEV && <DevTool control={form.control} />}
						<div
							ref={stickyRef}
							className="sticky top-16 h-[50vh] print:hidden"
						>
							<div className="absolute -top-[4.4rem] right-0 flex gap-2">
								{enableShipmentNavigation && (
									<>
										<Button
											variant="outline"
											className="flex items-center gap-1"
											onClick={() =>
												handleShipmentNavigation(
													prevShipment.current?.shipmentNumber,
												)
											}
											disabled={!prevShipment.current?.shipmentNumber}
										>
											<Icon className="size-3" icon={faChevronLeft} />
											{t('pages.shipment-detail.actions.previous', 'Previous')}
										</Button>

										<Button
											variant="outline"
											className="flex items-center gap-1"
											onClick={() =>
												handleShipmentNavigation(
													nextShipment.current?.shipmentNumber,
												)
											}
											disabled={!nextShipment.current?.shipmentNumber}
										>
											{t('pages.shipment-detail.actions.next', 'Next')}
											<Icon className="size-3" icon={faChevronRight} />
										</Button>
									</>
								)}
								{primaryActions?.map((action, index) => (
									<Button
										key={`primary-action-${index}`}
										variant="outline"
										className="flex items-center gap-2"
										onClick={() => action.handleOnClick()}
									>
										{action.label}
									</Button>
								))}
								{secondaryActions && (
									<DropdownMenu>
										<DropdownMenuTrigger asChild>
											<Button
												variant="outline"
												className="flex items-center gap-1"
											>
												<Icon icon={faEllipsisVertical} />
											</Button>
										</DropdownMenuTrigger>
										<DropdownMenuContent align="end">
											{secondaryActions?.map((action, index) => (
												<DropdownMenuItem
													key={`secondary-action-${index}`}
													onClick={() => action.handleOnClick()}
												>
													{action.label}
												</DropdownMenuItem>
											))}
										</DropdownMenuContent>
									</DropdownMenu>
								)}
							</div>
							<ShipmentMapSection
								shipmentStatus={shipmentStatus}
								initLocationActions={oldLocationActions}
							/>
							<SummarySection
								transportType={initData?.transportType}
								targetPrice={formatPrice(
									Math.round(watchPrice ?? 0),
									watchPriceCurrencyCode,
								)}
								totalPrice={formatPrice(
									shipment?.data?.priceIncludingShipmentFee?.quantity ?? 0,
									shipment?.data?.priceIncludingShipmentFee?.currencyCode ??
										'EUR',
								)}
								feePrice={formatPrice(
									shipment?.data?.shipmentFee?.quantity ?? 0,
									shipment?.data?.shipmentFee?.currencyCode ?? 'EUR',
								)}
								shipmentStatus={shipmentStatus ?? ShipmentStatus.UNKNOWN}
							>
								{!(
									shipmentTsoStatus === OrderStatus.CANCELLED ||
									(shipmentTsoStatus === undefined &&
										shipmentStatus === ShipmentStatus.CANCELLED &&
										numberOfInvoicedLines < 1)
								) && (
									<SideBarActions
										shipmentStatus={shipmentStatus ?? ShipmentStatus.UNKNOWN}
										isPublishing={!watchIsNotPublishing}
										isSubmitting={updatingShipment}
										onUpdate={handleFormSubmit}
										onPublish={handlePublish}
										onUnpublish={() => setOpenCancelShipmentModal(true)}
										onCancel={() => setOpenCancelShipmentModal(true)}
										isDirty={form.formState.isDirty}
									/>
								)}
							</SummarySection>
						</div>
					</Form>
				</Grid>
				<>
					<EventsShipmentHistorySheet
						openSheet={openEventSheet}
						setOpenSheet={setOpenEventSheet}
						shipmentNumber={id ?? ''}
					/>
					<QuotesSheet
						openSheet={openQuoteSheet}
						setOpenSheet={setOpenQuoteSheet}
						shipmentNumber={Number(id)}
					/>
					<ShareShipmentSheet
						openSheet={openShareSheet}
						setOpenSheet={setOpenShareSheet}
						shipmentNumber={Number(id)}
					/>
					<ChangeRequestModal
						openModal={openChangeRequestModal}
						setOpenModal={setOpenChangeRequestModal}
						changeRequestProps={{
							handleSubmit: () => {
								promptChangeRequestModal.current = false;
								handleFormSubmit();
							},
						}}
					/>
					{isShipmentCancellable(shipmentStatus) && (
						<CancelOrUnpublishShipmentModal
							shipmentNumber={Number(id)}
							shipmentStatus={shipmentStatus}
							shipmentQueryKey={shipmentQueryKey}
							eventsQueryKey={getGetEventsByShipmentNumberQueryKey(Number(id))}
							openModal={openCancelShipmentModal}
							setOpenModal={setOpenCancelShipmentModal}
						/>
					)}
				</>
			</Page>
		</>
	);
}
