import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import type { ShipmentPriceRequestDtoFeeTransportType } from '@uturn/api/v2';
import { useCalculateShipmentPrice } from '@uturn/api/v2';
import { ShipmentStatus } from '@uturn/api/v3';
import { Button, Icon, Separator } from '@uturn/ui-kit';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import type { FormValues } from '../schema';
import { formatPrice } from '@uturn/portal/utils';

type SummarySectionProps = {
	transportType: string | undefined;
	targetPrice?: string;
	totalPrice?: string | null;
	feePrice?: string;
	children?:
		| React.ReactNode
		| ((props: { totalPrice: string | null }) => React.ReactNode);
	isShipmentCreation?: boolean;
	shipmentStatus?: ShipmentStatus;
};

type TransportType = {
	name: string;
	value: ShipmentPriceRequestDtoFeeTransportType;
};

const TRANSPORT_TYPES: TransportType[] = [
	{ name: 'import', value: 'IMPORT' },
	{ name: 'export', value: 'EXPORT' },
	{ name: 'shunt', value: 'SHUNT' },
	{ name: 'other', value: 'OTHER' },
];

const SummarySection = ({
	transportType,
	children,
	targetPrice,
	totalPrice,
	feePrice,
	shipmentStatus,
}: SummarySectionProps) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const form = useFormContext<FormValues>();

	const { mutate, isLoading, data } = useCalculateShipmentPrice();

	// When price is final, the source of truth for price breakdown is the shipment.
	// No need to invoke the API to calculate the price.
	//
	// TODO: Alternative suggestion for isPriceFinal, using the hook to get the read-only fields
	// should be considered. IMHO, it's a safer way to determine that the price is final or not:
	//
	// import { useContext } from 'react';
	// import { ShipmentDetailContext } from '@uturn/portal/modules/shipments/providers/shipment-detail';
	//
	// const { readOnlyFields } = useContext(ShipmentDetailContext);
	// const isPriceFinal = isFieldReadOnly(readOnlyFields, 'price.quantity')
	//                   && isFieldReadOnly(readOnlyFields, 'preferredCarrierIds');
	const isPriceFinal =
		shipmentStatus === ShipmentStatus.CARRIER_ASSIGNED ||
		shipmentStatus === ShipmentStatus.COST_DECLARED ||
		shipmentStatus === ShipmentStatus.EXECUTED ||
		shipmentStatus === ShipmentStatus.COMPLETED;

	const watchTargetPrice = form.watch('price.quantity');
	const watchPreferredCarrierIds = form.watch('preferredCarrierIds');

	const isContractShipment = (): boolean => {
		return watchPreferredCarrierIds
			? watchPreferredCarrierIds.length > 0
			: false;
	};
	const priceCurrencyCode = form.getValues('price.currencyCode');
	const transportTypeObj = TRANSPORT_TYPES.find(
		(type) => type.name === transportType,
	);
	const transportTypeValue = transportTypeObj?.value;

	const debouncedMutate = useDebouncedCallback((price: number) => {
		mutate({
			data: {
				shipmentPrice: price,
				feeType: isContractShipment() ? 'CONTRACT' : 'MARKET',
				feeTransportType: transportTypeValue || 'OTHER',
			},
		});
	}, 300);

	useEffect(() => {
		if (!isPriceFinal && watchTargetPrice && watchTargetPrice > 0) {
			debouncedMutate(watchTargetPrice);
		}
	}, [
		isPriceFinal,
		debouncedMutate,
		watchTargetPrice,
		watchPreferredCarrierIds,
	]);

	form.watch((value, { name }) => {
		if (
			!isPriceFinal &&
			name === 'price.quantity' &&
			typeof value?.price?.quantity === 'number' &&
			value.price.quantity > 0
		) {
			debouncedMutate(value.price.quantity);
		}
	});

	const calculatedFormattedPrices = useMemo(() => {
		return {
			targetPrice: watchTargetPrice
				? formatPrice(watchTargetPrice, priceCurrencyCode)
				: t('shipment-form.summary_section.target_price_n_a', 'N/A'),
			feePrice:
				data?.data?.shipmentFee?.quantity && watchTargetPrice
					? formatPrice(data.data.shipmentFee.quantity, priceCurrencyCode)
					: t('shipment-form.summary_section.fee_price_n_a', 'N/A'),
			totalPrice: data?.data?.shipmentPriceTotal?.quantity
				? formatPrice(data.data.shipmentPriceTotal.quantity, priceCurrencyCode)
				: null,
		};
	}, [
		data?.data.shipmentFee?.quantity,
		data?.data.shipmentPriceTotal?.quantity,
		priceCurrencyCode,
		t,
		watchTargetPrice,
	]);

	const renderTotalPriceLabel = () => {
		if (isPriceFinal) {
			return (
				<p className="font-bold">
					{t(
						'shipment-form.summary_section.agreedQuotePrice.label',
						'Agreed total price',
					)}
				</p>
			);
		}

		return (
			<div className="flex items-center">
				<span className="font-bold">
					{t(
						'pages.create_shipment.summary_section.estimated_total_price.label',
						'Estimated total price',
					)}
				</span>
				<span>*</span>
			</div>
		);
	};

	return (
		<>
			<div className="mt-2 flex items-center justify-between">
				<h3 className="font-heading my-0 text-lg font-medium tracking-tight">
					{t('shipment-form.summary_section.title', 'Summary')}
				</h3>

				<Button className="p-0" variant="link" onClick={() => setOpen(!open)}>
					{!open
						? t('shipment-form.view-breakdown.label', 'View breakdown')
						: t('shipment-form.hide-breakdown.label', 'Hide breakdown')}
				</Button>
			</div>

			<AnimatePresence>
				{open && (
					<motion.div
						initial={{ height: 0, opacity: 0 }}
						animate={{ height: 'auto', opacity: 1 }}
						exit={{ height: 0, opacity: 0 }}
					>
						<div>
							<div className="text-muted-foreground flex justify-between text-sm">
								<p className="text-muted-foreground">
									{isPriceFinal
										? t(
												'shipment-form.summary_section.target_price.label',
												'Target price',
											)
										: t(
												'shipment-form.summary_section.target_price.label',
												'Target price',
											)}
								</p>

								<span>
									{targetPrice ?? calculatedFormattedPrices.targetPrice}
								</span>
							</div>
							<div className="text-muted-foreground flex justify-between text-sm">
								<p>{t('shipment-form.summary_section.fee.label', 'Fee')}</p>
								<span>
									{isLoading ? (
										<Icon
											className="text-primary"
											size="sm"
											icon={faCircleNotch}
											spin
										/>
									) : (
										<>
											{isPriceFinal
												? feePrice
												: calculatedFormattedPrices.feePrice}
										</>
									)}
								</span>
							</div>
						</div>
					</motion.div>
				)}
			</AnimatePresence>

			<Separator className="my-2" />

			<div className="flex items-center justify-between ">
				{renderTotalPriceLabel()}

				<span>
					{isLoading ? (
						<Icon
							className="text-primary"
							size="sm"
							icon={faCircleNotch}
							spin
						/>
					) : (
						<>
							<span className="font-bold">
								{isPriceFinal
									? totalPrice
									: calculatedFormattedPrices.totalPrice}
							</span>
							{!calculatedFormattedPrices.totalPrice && !totalPrice && (
								<span className="text-muted-foreground text-sm">
									{t(
										'shipment-form.summary_section.total_price_n_a',
										'Insert the target price',
									)}
								</span>
							)}
						</>
					)}
				</span>
			</div>
			{!isPriceFinal && (
				<div className="text-muted-foreground flex text-xs text-gray-500/60">
					<span>*</span>
					<p>
						{t(
							'pages.create_shipment.summary_section.total_price_disclaimer',
							'The total price may vary based on carrier quotes and the final platform fee',
						)}
					</p>
				</div>
			)}
			<div className="mt-6">
				{children && typeof children === 'function'
					? children({ totalPrice: calculatedFormattedPrices.totalPrice })
					: children}
			</div>
		</>
	);
};

export default SummarySection;
