import { Separator, buttonVariants, cn } from '@uturn/ui-kit';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
	items: {
		href: string;
		title: string;
	}[];
}

export function SidebarNav({ className, items, ...props }: SidebarNavProps) {
	const { pathname } = useLocation();

	return (
		<nav
			className={cn(
				'flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1',
				className,
			)}
			{...props}
		>
			{items.map((item) => (
				<Link
					key={item.href}
					to={item.href}
					className={cn(
						buttonVariants({ variant: 'ghost' }),
						pathname === item.href
							? 'bg-accent hover:bg-accent text-accent-foreground'
							: 'hover:bg-transparent hover:underline',
						'justify-start',
					)}
				>
					{
						// TODO: Translate this
						item.title
					}
				</Link>
			))}
		</nav>
	);
}

const sidebarNavItems = (orgId: number) => [
	{
		title: 'Profile',
		href: `/org/${orgId}/settings`,
	},
	{
		title: 'Billing',
		href: `/org/${orgId}/settings/billing`,
	},
	{
		title: 'Documents',
		href: `/org/${orgId}/settings/documents`,
	},
	{
		title: 'Users',
		href: `/org/${orgId}/settings/users`,
	},
	{
		title: 'Subscriptions',
		href: `/org/${orgId}/settings/subscriptions`,
	},
	{
		title: 'Shipment settings',
		href: `/org/${orgId}/settings/shipment-settings`,
	},
];

export function OrganizationLayout() {
	const { orgId } = useParams();
	const { t } = useTranslation();

	return (
		<>
			<Helmet
				title={t('pages.account.organisation.title', 'Organisation settings')!}
			/>
			<div className="py-8 pt-6 @container/content contain-layout md:block">
				<div className="space-y-0.5">
					<h2 className="text-3xl font-bold tracking-tight">
						{t('pages.account.organisation.title', 'Organisation settings')}
					</h2>
					<p className="text-muted-foreground">
						{t(
							'pages.account.organisation.description',
							'Manage the profile and settings for your organisation',
						)}
					</p>
				</div>
				<Separator className="my-6" />
				<div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
					<aside className="-mx-4 lg:w-1/5">
						<SidebarNav items={sidebarNavItems(Number(orgId))} />
					</aside>
					<div className="flex-1 lg:max-w-2xl">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
