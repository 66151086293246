import { Partytown } from '@builder.io/partytown/react';
import { Helmet } from 'react-helmet-async';
import { Usetiful } from './services';

export function Scripts() {
	Usetiful.init();

	return (
		<>
			<Partytown debug={import.meta.env.DEV} />
			<Helmet>
				<script
					type="text/javascript"
					async
					defer
					src="//js.hs-scripts.com/4894764.js"
				/>
				<script type="text/javascript">
					{`(function (w, d, s) {
						var a = d.getElementsByTagName('head')[0];
						var r = d.createElement('script');
						r.async = 1;
						r.src = s;
						r.setAttribute('id', 'usetifulScript');
						r.dataset.token = 'eff4cae12d2c405ac262220e39675655';
						a.appendChild(r);
					})(window, document, 'https://www.usetiful.com/dist/usetiful.js')`}
				</script>
			</Helmet>
		</>
	);
}
