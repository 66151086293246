import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import type { ColumnDef } from '@tanstack/react-table';
import {
	flexRender,
	getCoreRowModel,
	useReactTable,
} from '@tanstack/react-table';
import { type OrganizationDto, useUpdateOrganization } from '@uturn/api/v1';
import type { FeeDto, FeeDtoFeeUnit, PriceDto } from '@uturn/api/v3';
import { useFetchOrganizationById } from '@uturn/api/v3';
import { phoneDialCodes } from '@uturn/ui';
import {
	Button,
	Form,
	FormControl,
	FormField,
	FormFieldReadOnly,
	FormItem,
	FormLabel,
	FormMessage,
	Icon,
	Input,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
	Separator,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
	sonner,
} from '@uturn/ui-kit';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { formatPrice } from '@uturn/portal/utils';

const countryOptions = phoneDialCodes.map((country) => ({
	label: country.name,
	value: country.code,
}));

const formatFee = (fee: PriceDto, feeUnit: FeeDtoFeeUnit | undefined) => {
	if (fee.quantity === 0) {
		return '';
	}

	if (feeUnit === 'PERCENTAGE') {
		return `${fee.quantity}%`;
	}

	return formatPrice(fee.quantity, fee.currencyCode);
};

type Initial = Record<
	string,
	{
		feeType: string;
		transportData: Record<
			string,
			{ fee?: string; minFee?: string; maxFee?: string }
		>;
	}
>;

const reshapeData = (data: FeeDto[]) => {
	const initial: Initial = {};

	const grouped = data.reduce((acc, cur) => {
		const { feeType, feeTransportType, fee, feeUnit, minFee, maxFee } = cur;

		// Map EXPORT, IMPORT, SHUNT to DRAYAGE
		const normalizedTransportType =
			feeTransportType === 'EXPORT' ||
			feeTransportType === 'OTHER' ||
			feeTransportType === 'IMPORT'
				? 'DRAYAGE'
				: feeTransportType;

		if (!feeType || !normalizedTransportType) {
			return {};
		}

		if (!acc[feeType]) {
			acc[feeType] = {
				feeType:
					feeType.charAt(0).toUpperCase() + feeType.slice(1).toLowerCase(),
				transportData: {},
			};
		}

		if (!acc[feeType].transportData[normalizedTransportType]) {
			acc[feeType].transportData[normalizedTransportType] = {
				fee: fee ? formatFee(fee, feeUnit) : '',

				minFee:
					!minFee || minFee?.quantity === 0
						? ''
						: formatPrice(minFee?.quantity, minFee.currencyCode),
				maxFee:
					!maxFee || maxFee.quantity === 0
						? ''
						: formatPrice(maxFee.quantity, maxFee.currencyCode),
			};
		}

		return acc;
	}, initial);

	return Object.values(grouped).map(({ feeType, transportData }) => ({
		feeType,
		...transportData,
	}));
};

type FeeRow = {
	feeType: string;
	DRAYAGE?: { fee: string; minFee: string; maxFee: string };
	OTHER?: { fee: string; minFee: string; maxFee: string };
};

export function OrganizationBilling() {
	const { orgId } = useParams();
	const { t } = useTranslation();
	const { data: organization } = useFetchOrganizationById(Number(orgId), {
		query: {
			select: (data) => data.data,
		},
	});

	const feeSettings = useMemo(() => organization?.fees ?? [], [organization]);

	const feeSettingsGrouped = useMemo(
		() => reshapeData(feeSettings),
		[feeSettings],
	);

	const columns = useMemo<ColumnDef<FeeRow>[]>(
		() => [
			{
				header: '',
				id: 'feeType',
				accessorKey: 'feeType',

				columns: [
					{
						header: 'Fee Type',
						accessorKey: 'feeType',
						id: 'feeTypeHeader',
					},
				],
			},

			{
				header: t(
					'pages.organisation.billing.feeSettings.feeTransportType.drayage',
					'Drayage',
				)!,
				id: 'drayageGroup',
				columns: [
					{ header: 'Fee', accessorKey: 'DRAYAGE.fee', id: 'drayage' },
					{ header: 'Min', accessorKey: 'DRAYAGE.minFee', id: 'shuntMinFee' },
					{ header: 'Max', accessorKey: 'DRAYAGE.maxFee', id: 'shuntMaxFee' },
				],
			},
			{
				header: t(
					'pages.organisation.billing.feeSettings.feeTransportType.other',
					'Shunt',
				)!,
				id: 'shuntGroup',
				columns: [
					{ header: 'Fee', accessorKey: 'SHUNT.fee', id: 'shunt' },
					{ header: 'Min', accessorKey: 'SHUNT.minFee', id: 'otherMinFee' },
					{ header: 'Max', accessorKey: 'SHUNT.maxFee', id: 'otherMaxFee' },
				],
			},
		],
		[],
	);

	const table = useReactTable({
		data: feeSettingsGrouped,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	const { mutate } = useUpdateOrganization({
		mutation: {
			onSuccess: () => {
				sonner.success(
					t(
						'pages.account.organisation.billing.form.submit.success.description',
						'Organisation has been updated!',
					),
				);
			},
			onError: () => {
				sonner.error(t('general.try-again-later', 'Please try again later'));
			},
		},
	});

	const form = useForm<OrganizationDto>({
		defaultValues: organization!,
	});

	return (
		<>
			<Helmet
				title={
					t(
						'pages.account.organisation.billing.title',
						'Organisation - Billing',
					)!
				}
			/>
			<div className="space-y-6">
				<div>
					<h3 className="text-lg font-medium">
						{t('pages.account.organisation.billing.title', 'Billing')}
					</h3>
					<p className="text-muted-foreground text-sm">
						{t(
							'pages.account.organisation.billing.description',
							'Manage your billing information',
						)}
					</p>
				</div>
				<Separator />
				<Form {...form}>
					<form
						className="space-y-8"
						onSubmit={form.handleSubmit((formData) => {
							mutate({
								id: Number(orgId),
								data: formData satisfies OrganizationDto,
							});
						})}
					>
						<div className="grid grid-cols-1 gap-3">
							<FormField
								control={form.control}
								disabled
								name="financialDetails.currency"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.account.organisation.billing.form.general.fields.invoice-currency',
												'Invoice currency',
											)}
										</FormLabel>
										<FormControl>
											<FormFieldReadOnly value={field.value} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								disabled
								name="financialDetails.vatNumber"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.account.organisation.billing.form.general.fields.vat-number',
												'Tax number (VAT)',
											)}
										</FormLabel>
										<FormControl>
											{field.value !== '' ? (
												<FormFieldReadOnly value={field.value!} />
											) : (
												<Input {...field} />
											)}
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<div className="grid grid-cols-2 gap-3">
								<FormField
									control={form.control}
									disabled
									name="financialDetails.bankDetails.accountNumber"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t(
													'pages.account.organisation.billing.form.general.fields.account-number',
													'Bank account number',
												)}
											</FormLabel>
											<FormControl>
												{field.value !== '' ? (
													<FormFieldReadOnly value={field.value!} />
												) : (
													<Input {...field} />
												)}
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									disabled
									name="financialDetails.bankDetails.bic"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t(
													'pages.account.organisation.billing.form.general.fields.bic',
													'BIC',
												)}
											</FormLabel>
											<FormControl>
												{field.value !== '' ? (
													<FormFieldReadOnly value={field.value!} />
												) : (
													<Input {...field} />
												)}
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
						</div>

						<div className="grid grid-cols-2 gap-3">
							<h3 className="text-md font-heading col-span-full font-medium tracking-tight">
								{t(
									'pages.account.organisation.billing.form.chamber-of-commerce.title',
									'Chamber of commerce',
								)}
							</h3>
							<FormField
								control={form.control}
								name="financialDetails.chamberOfCommerce.number"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.account.organisation.billing.form.chamber-of-commerce.fields.number',
												'Number',
											)}
										</FormLabel>
										<FormControl>
											{field.value !== '' ? (
												<FormFieldReadOnly value={field.value!} />
											) : (
												<Input {...field} />
											)}
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>

							<FormField
								control={form.control}
								name="address.countryCode"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.account.organisation.billing.form.chamber-of-commerce.fields.country.label',
												'Country',
											)}
										</FormLabel>
										{field.value !== '' ? (
											<FormFieldReadOnly
												value={
													countryOptions.filter(
														(country) => country.value === field.value,
													)[0].label
												}
											/>
										) : (
											<Select
												onValueChange={field.onChange}
												defaultValue={field.value}
											>
												<FormControl>
													<SelectTrigger>
														<SelectValue
															placeholder={
																<span>
																	{t(
																		'pages.account.organisation.billing.form.chamber-of-commerce.fields.country.placeholder',
																		'Select where you are located',
																	)}
																</span>
															}
														/>
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													{countryOptions.map((country) => {
														return (
															<SelectItem
																key={country.value}
																value={country.value}
															>
																{country.label}
															</SelectItem>
														);
													})}
												</SelectContent>
											</Select>
										)}
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>

						<Separator />

						<div>
							<h3 className="text-md font-heading mb-4 font-medium tracking-tight">
								{t(
									'pages.account.organisation.billing.form.fee-settings.title',
									'Fee Settings',
								)}
							</h3>

							<div className="rounded-md border">
								<Table>
									<TableHeader>
										{table.getHeaderGroups().map((headerGroup) => (
											<TableRow key={headerGroup.id}>
												{headerGroup.headers.map((header) => (
													<TableHead key={header.id} colSpan={header.colSpan}>
														{flexRender(
															header.column.columnDef.header,
															header.getContext(),
														)}
													</TableHead>
												))}
											</TableRow>
										))}
									</TableHeader>
									<TableBody>
										{table.getRowModel().rows.map((row) => (
											<TableRow key={row.id}>
												{row.getVisibleCells().map((cell) => (
													<TableCell key={cell.id}>
														{flexRender(
															cell.column.columnDef.cell,
															cell.getContext(),
														)}
													</TableCell>
												))}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</div>
						</div>

						<Separator />

						<div className="space-y-3">
							<h3 className="text-md font-heading font-medium tracking-tight">
								{t(
									'pages.account.organisation.billing.form.invoice-address.title',
									'Invoice address',
								)}
							</h3>
							<div className="grid grid-cols-[3fr,_1fr] gap-3">
								<FormField
									control={form.control}
									name="financialDetails.address.street"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t(
													'pages.account.organisation.billing.form.invoice-address.fields.street',
													'Street',
												)}
											</FormLabel>
											<FormControl>
												<Input {...field} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="financialDetails.address.houseNumber"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t(
													'pages.account.organisation.billing.form.invoice-address.fields.house-number',
													'House number',
												)}
											</FormLabel>
											<FormControl>
												<Input {...field} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>

							<div className="grid grid-cols-[2fr,_4fr] gap-3 ">
								<FormField
									control={form.control}
									name="financialDetails.address.postalCode"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t(
													'pages.account.organisation.billing.form.invoice-address.fields.postal-code',
													'Postal code',
												)}
											</FormLabel>
											<FormControl>
												<Input {...field} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="financialDetails.address.city"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t(
													'pages.account.organisation.billing.form.invoice-address.fields.city',
													'City',
												)}
											</FormLabel>
											<FormControl>
												<Input {...field} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>

							<FormField
								control={form.control}
								name="financialDetails.address.countryCode"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t(
												'pages.account.organisation.billing.form.invoice-address.fields.country.label',
												'Country',
											)}
										</FormLabel>
										<Select
											onValueChange={field.onChange}
											defaultValue={field.value}
										>
											<FormControl>
												<SelectTrigger>
													<SelectValue
														placeholder={
															<span>
																{t(
																	'pages.account.organisation.billing.form.invoice-address.fields.country.placeholder',
																	'Select where you are located',
																)}
															</span>
														}
													/>
												</SelectTrigger>
											</FormControl>
											<SelectContent>
												{countryOptions.map((country) => {
													return (
														<SelectItem
															key={country.value}
															value={country.value}
														>
															{country.label}
														</SelectItem>
													);
												})}
											</SelectContent>
										</Select>
										<FormMessage />
									</FormItem>
								)}
							/>

							<Button type="submit" disabled={form.formState.isSubmitting}>
								{form.formState.isSubmitting && (
									<Icon spin className="mr-3" icon={faSpinnerThird} />
								)}
								{t('general.actions.save', 'save')}
							</Button>
						</div>
					</form>
				</Form>
			</div>
		</>
	);
}
