import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import { ConditionType, type CreateShipment } from '@uturn/api/v1';
import { Grid } from '@uturn/ui';
import { AlertDialog, AlertDialogContent, Form, sonner } from '@uturn/ui-kit';
import posthog from 'posthog-js';
import { useContext, useEffect, useRef, useState } from 'react';
import { useAbac } from 'react-abac';
import { Helmet } from 'react-helmet-async';
import type { FieldErrors } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SummarySection from '../../components/summary-section';
import { getFirstErrorFieldRef } from '../../utils/form-errors';
import { getShipmentModalContent } from './shipment-modal-content';
import { SideBarActions } from './sidebar-actions';
import { StickyBarActions } from './sticky-bar-actions';
import { Permission } from '@uturn/portal/abac';
import { Page } from '@uturn/portal/components/page';
import { MetaDataContext } from '@uturn/portal/context';
import {
	PublishDetailsSection,
	RouteSection,
	ShipmentDetailsSection,
	ShipmentMapSection,
} from '@uturn/portal/modules/shipments/components';
import { formSchema } from '@uturn/portal/modules/shipments/schema';
import type {
	FormValues,
	ZodLocation,
} from '@uturn/portal/modules/shipments/schema';
import {
	fromForm,
	fromZodLocations,
	shouldUpdate,
	typeOfShipmentName,
} from '@uturn/portal/modules/shipments/utils';
import {
	useGrossWeightValidation,
	useGroupedShipmentValidation,
	useLocationDatesValidation,
	usePriceValidation,
	useShipperReferenceValidation,
} from '@uturn/portal/modules/shipments/validation';
import { useAddShipmentStore } from '@uturn/portal/store/shipments/add-shipment';
import {
	ContainerStatus,
	TypeOfShipment,
	initDocuments,
} from '@uturn/portal/types/shipment';
import type { Documents } from '@uturn/portal/types/shipment';
import { formatPrice } from '@uturn/portal/utils';

export function ManualShipment() {
	const {
		initData,
		copyData,
		transportType,
		typeOfShipment,
		orgDefaultValues,
		currencyCode,
		createShipment,
	} = useAddShipmentStore();
	const { t } = useTranslation();
	const { userHasPermissions } = useAbac();
	const { validateLocationDates } = useLocationDatesValidation();
	const { validateGrossWeight } = useGrossWeightValidation();
	const { validateShipperReference } = useShipperReferenceValidation();
	const { validateGroupedShipment } = useGroupedShipmentValidation();
	const { validatePrice } = usePriceValidation();
	const { shipmentMetaData } = useContext(MetaDataContext);
	const navigate = useNavigate();

	const [sendingShipmentData, setSendingShipmentData] = useState(false);
	const [openSavedPublishedModal, setOpenSavedPublishedModal] = useState(false);
	const [shipmentNumber, setShipmentNumber] = useState('');
	const [newFiles, setNewFiles] = useState<Documents>(initDocuments);

	const initDataLoaded = Object.keys(initData)?.length > 0;
	const copyDataLoaded = Object.keys(copyData)?.length > 0;
	const storeNotFound = transportType && !initDataLoaded && !copyDataLoaded;

	const form = useForm<Partial<FormValues>>({
		resolver: zodResolver(formSchema),
		defaultValues: copyDataLoaded ? copyData : initData,
		mode: 'onBlur',
	});

	// const [fieldFocus, setFieldFocus] = useState<keyof FormValues | undefined>(undefined);
	const [isCrossBorderShipment, setIsCrossBorderShipment] =
		useState<boolean>(false);
	const [isGBRShipment, setIsGBRShipment] = useState(false);

	const formRef = useRef<HTMLFormElement>(null);

	const watchTargetPrice = form.watch('price.quantity');
	const watchIsConcept = form.watch('concept');
	const priceCurrencyCode = form.getValues('price.currencyCode');
	/**
	 * // TODO: Revisit the setIsCrossBorderShipment logic because and FR - UK shipment
	 *          is also cross-border, right?
	 *
	 * Currently we check both the 2 letter notation aswell as the 3 letter notation
	 * The reason for this is that the shipment endpoint which loads the initial data returns
	 * the 2 letter notation, but the autocomplete returns the 3 letter notation
	 *
	 * This counts for both the hasCrossBorderCountry and isGBRShipment check
	 *
	 */
	const setApplicableConditions = (values: Partial<FormValues>) => {
		const { countryCodes } = fromZodLocations(
			values.locations as ZodLocation[],
		);

		setIsCrossBorderShipment(
			countryCodes.length > 0 &&
				countryCodes.some((country) => country !== 'NLD' && country !== 'NL'),
		);
		setIsGBRShipment(
			countryCodes.length > 0 &&
				countryCodes.every((country) => country === 'GBR' || country === 'GB'),
		);
	};

	useEffect(() => {
		const subscription = form.watch((value, { name }) => {
			if (name?.match(/^locations\.[0-9]*\.countryCode/)) {
				setApplicableConditions(value);
			}
		});

		return () => subscription.unsubscribe();
	}, [form.watch]);

	useEffect(() => {
		// const { conditions: defaultConditions } = orgDefaultValues ?? {};
		switch (true) {
			// case defaultConditions:
			// 	form.setValue('conditions', defaultConditions, shouldUpdate);
			// 	break;
			case isGBRShipment:
				form.setValue('conditions', ConditionType.RHA, shouldUpdate);
				break;
			case isCrossBorderShipment:
				form.setValue('conditions', ConditionType.CMR, shouldUpdate);
				break;
			default:
				form.setValue('conditions', ConditionType.AVC, shouldUpdate);
				break;
		}
	}, [isCrossBorderShipment, isGBRShipment, orgDefaultValues]);

	// useEffect(() => {
	// 	form.setFocus(fieldFocus, { shouldSelect: true });
	// }, [form.setFocus, fieldFocus]);

	const handleFormSubmit = () => {
		if (formRef.current === null) return;

		form.clearErrors();

		formRef.current.dispatchEvent(
			new Event('submit', { cancelable: true, bubbles: true }),
		);
	};

	const handleConcept = () => {
		form.setValue('concept', true, shouldUpdate);

		posthog.capture('shipment_published', {
			name: 'button_save_concept',
			transport_type: transportType,
			shipment_type: typeOfShipment,
			group_name: form.getValues('newGroup.name') ?? '',
			number_of_shipments: form.getValues('numberOfCopiesToBeCreated') ?? 1,
			target_price: form.getValues('price.quantity') ?? 0,
		});

		handleFormSubmit();
	};

	const handlePublish = () => {
		form.setValue('concept', false, shouldUpdate);

		if (!userHasPermissions(Permission.PUBLISH_SHIPMENT)) {
			setOpenSavedPublishedModal(true);
			return;
		}

		posthog.capture('shipment_published', {
			name: 'button_publish',
			transport_type: transportType,
			shipment_type: typeOfShipment,
			group_name: form.getValues('newGroup.name') ?? '',
			number_of_shipments: form.getValues('numberOfCopiesToBeCreated') ?? 1,
			target_price: form.getValues('price.quantity') ?? 0,
		});

		handleFormSubmit();
	};

	const isFormValid = (data: FormValues) => {
		form.clearErrors();

		const publishing = !(data.concept ?? true);

		let results = validateGroupedShipment(
			data.newGroup?.name ?? '',
			data.numberOfCopiesToBeCreated ?? 1,
			typeOfShipment,
		);

		results = validateGrossWeight(
			data.grossWeight?.quantity ?? 0,
			data.containerStatus ?? ContainerStatus.FULL,
			publishing,
			results,
		);

		results = validateLocationDates(
			data.locationActions,
			data.publishUntilDate ?? '',
			publishing,
			true,
			results,
		);

		results = validatePrice(data.price?.quantity ?? 0, publishing, results);

		results = validateShipperReference(
			data.shipperReference ?? '',
			data.invoiceRequired ?? true,
			publishing,
			results,
		);

		if (!results.valid) {
			for (let i = 0; i < results.messages.length; i += 1) {
				const message = results.messages[i];
				const path = results.paths[i];
				form.setError(path, {
					type: 'custom',
					message,
				});
			}
			form.setFocus(results.paths[0], { shouldSelect: true });
			return false;
		}

		return true;
	};

	const onSubmit = async (data: FormValues) => {
		if (!isFormValid(data)) {
			return false;
		}

		const postData = fromForm(data) as Partial<CreateShipment>;

		const formData = new FormData();

		if (newFiles.fileRequests.length > 0 && newFiles.files.length > 0) {
			postData.fileRequests = newFiles.fileRequests;
			newFiles.files.forEach((file) => {
				formData.append('files', file);
			});
		}

		formData.append(
			'shipment',
			new Blob([JSON.stringify(postData)], {
				type: 'application/json',
			}),
		);

		setSendingShipmentData(true);
		const result = await createShipment(formData);
		setSendingShipmentData(false);

		if (result.status === 200) {
			setShipmentNumber(result.data[0] as unknown as string);
			return setOpenSavedPublishedModal(true);
		}

		const error = result as { message: string } | any; // ErrorResponse;

		sonner.error(
			typeOfShipment === TypeOfShipment.GROUP
				? t(
						'pages.create_grouped_shipment.form.submit.error.description',
						'Could not save identical {{transportType}} shipments. {{errorMessage}}',
						{
							transportType: typeOfShipmentName(t)[typeOfShipment],
							errorMessage: error.message,
						},
					)
				: t(
						'pages.create_shipment.form.submit.error.description',
						'Could not save {{transportType}} shipment. {{errorMessage}}',
						{
							transportType: typeOfShipmentName(t)[typeOfShipment],
							errorMessage: error.message,
						},
					),
		);

		return false;
	};

	const onError = (errors: FieldErrors<FormValues>) => {
		if (import.meta.env.DEV) {
			const firstRef = getFirstErrorFieldRef(errors);
			// eslint-disable-next-line no-console
			console.log('ManualShipment.onError', {
				errors,
				firstRef,
			});
			// setFieldFocus(firstRef);
		}
	};

	// On page refresh, the store is reset. Need to go back.
	if (storeNotFound) {
		return navigate('/shipments/add');
	}

	const title = (
		typeOfShipment === TypeOfShipment.GROUP
			? t(
					'pages.create_grouped_shipment.title',
					'Create identical {{transportType}} shipments',
					{ transportType: typeOfShipmentName(t)[typeOfShipment] },
				)
			: t('pages.create_shipment.title', 'Create {{transportType}} shipment', {
					transportType: typeOfShipmentName(t)[typeOfShipment],
				})
	)!;

	return (
		<>
			<Helmet title={title} />
			<Page
				title={title}
				backButton={{
					label: 'Add new shipment',
					href: '/shipments/add',
				}}
			>
				<Grid
					columns={[
						{
							type: '%',
							value: 60,
						},
						{
							type: 'auto',
						},
					]}
					className="relative min-h-screen pb-16"
					gap={2.5}
				>
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit, onError)} ref={formRef}>
							<div className="flex flex-col gap-4">
								<ShipmentDetailsSection
									containerTypes={shipmentMetaData!.shipmentUnits ?? []}
									shipmentRequirements={
										shipmentMetaData!.shipmentRequirements ?? []
									}
									shippingLines={shipmentMetaData!.shippingLines ?? []}
									setNewFiles={(files, fileRequests) => {
										setNewFiles({
											files,
											fileRequests,
										});
									}}
									enableAddingGroup={typeOfShipment === TypeOfShipment.GROUP}
								/>
								<RouteSection transportType={transportType ?? ''} />
								<PublishDetailsSection
									isCrossBorderShipment={isCrossBorderShipment}
									isGBRShipment={isGBRShipment}
								/>
							</div>
						</form>
						{import.meta.env.DEV && <DevTool control={form.control} />}
						<div className="sticky top-16 h-[50vh] print:hidden">
							<ShipmentMapSection />
							<SummarySection transportType={transportType} isShipmentCreation>
								{({ totalPrice }) => (
									<SideBarActions
										sendingShipmentData={sendingShipmentData}
										publishShipment={handlePublish}
										saveAsConcept={handleConcept}
										isConcept={watchIsConcept ?? true}
										isGroupedShipment={typeOfShipment === TypeOfShipment.GROUP}
										numberOfShipments={form.watch('numberOfCopiesToBeCreated')}
										isDirty={form.formState.isDirty}
										totalPrice={totalPrice ?? undefined}
									/>
								)}
							</SummarySection>
						</div>
					</Form>
				</Grid>
				<AlertDialog open={openSavedPublishedModal}>
					<AlertDialogContent className="max-w-[25rem] md:max-w-[40rem]">
						{getShipmentModalContent(
							watchIsConcept!,
							typeOfShipment,
							shipmentNumber,
							navigate,
							() => setOpenSavedPublishedModal(false),
							form.getValues('numberOfCopiesToBeCreated') ?? 0,
							form.getValues('newGroup.name'),
						)}
					</AlertDialogContent>
				</AlertDialog>
			</Page>
		</>
	);
}
