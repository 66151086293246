import type { ShipmentListDTO } from '@uturn/api/v1';

export function getNextPrevShipments({
	shipments,
	currentShipmentNumber,
}: {
	shipments?: ShipmentListDTO[];
	currentShipmentNumber: number;
}): { next?: ShipmentListDTO; prev?: ShipmentListDTO } {
	if (!shipments) {
		return { next: undefined, prev: undefined };
	}

	const currentShipmentIndex = shipments?.findIndex(
		(shipment) => shipment.shipmentNumber === currentShipmentNumber,
	);

	if (currentShipmentIndex === -1) {
		return { next: undefined, prev: undefined };
	}

	const next =
		currentShipmentIndex < shipments.length - 1
			? shipments[currentShipmentIndex + 1]
			: undefined;
	const prev =
		currentShipmentIndex > 0 ? shipments[currentShipmentIndex - 1] : undefined;

	return { next, prev };
}
