import { CurrencyType, type ShipmentListDTO } from '@uturn/api/v1';
import { ShipmentsHistory as ShipmentsHistoryEmptyState } from '@uturn/design-tokens/empty-states';
import { Button, EmptyState, Link } from '@uturn/ui';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ShipmentGroupDetailContext } from '../../providers/shipment-group-detail';
import {
	RequirementBadges,
	ShipmentPage,
} from '@uturn/portal/modules/shipments/components';
import { formatPrice } from '@uturn/portal/utils';

function ArchivedShipmentEmptyState() {
	const { t } = useTranslation();

	return (
		<EmptyState>
			<ShipmentsHistoryEmptyState className="md:h-80" />
			<h2 className="text-secondary-700 text-2xl font-medium leading-7">
				{t(
					'pages.shipments.archived.emptyState.title',
					'History is written here!',
				)}
			</h2>
			<p className="text-secondary-600 text-lg leading-6">
				{t(
					'pages.shipments.archived.emptyState.description',
					'This will be the place where we store your completed shipments.',
				)}
			</p>
			<div>
				<Link to="/shipments/add">
					<Button variant="primary" size="base">
						{t('pages.shipments.actions.start', 'Get started')}
					</Button>
				</Link>
			</div>
		</EmptyState>
	);
}

export function ArchivedGroupShipments() {
	const { t } = useTranslation();
	const { groupName } = useContext(ShipmentGroupDetailContext);

	const shipmentDetails = (shipment: ShipmentListDTO) => [
		{
			label: t('pages.shipments.details.reference', 'reference'),
			value: shipment.shipperReference,
			visibleOnMultiSelect: true,
			enableCopyToClipboard: true,
		},
		{
			label: t('pages.shipments.details.containerNumber', 'container number'),
			value: shipment.containerNumber,
			visibleOnMultiSelect: true,
			enableCopyToClipboard: true,
		},
		{
			label: t('pages.shipments.details.price', 'price'),
			value: formatPrice(
				shipment.priceIncludingShipmentFee?.quantity ?? 0,
				shipment.priceIncludingShipmentFee?.currencyCode ?? CurrencyType.EUR,
			),
			visibleOnMultiSelect: true,
			hasInfoIcon: true,
		},
		{
			label: t('pages.shipments.details.requirements', 'requirements'),
			value: shipment.requirements && (
				<RequirementBadges requirements={shipment.requirements} />
			),
		},
	];

	return (
		<>
			<Helmet
				title={
					t(
						'pages.shipments.groups.archived.title',
						'{{groupName}} - Archived shipments',
						{
							groupName,
						},
					)!
				}
			/>
			<ShipmentPage
				enableGroupFilter={false}
				EmptyState={ArchivedShipmentEmptyState}
				details={shipmentDetails}
			/>
		</>
	);
}
