import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { ShipmentStatus } from '@uturn/api/v3';
import {
	Button,
	Icon,
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
	Separator,
} from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';

type BreakdownTooltipProps = {
	feeValue: string;
	targetPrice: string;
	percentageValue?: string;
	totalPrice: string;
	shipmentStatus?: ShipmentStatus;
};

const BreakDownTooltip = ({
	feeValue,
	targetPrice,
	percentageValue,
	totalPrice,
	shipmentStatus,
}: BreakdownTooltipProps) => {
	const { t } = useTranslation();

	return (
		<TooltipProvider delayDuration={10}>
			<Tooltip>
				<TooltipTrigger asChild>
					<Button variant="ghost" className="w-5 h-5 px-2 ml-2">
						<Icon icon={faCircleExclamation} />
					</Button>
				</TooltipTrigger>
				<TooltipContent side="top" align="center" sideOffset={1}>
					<div className="min-w-[270px]">
						<div className="flex justify-between text-sm text-muted-foreground">
							<p className="text-muted-foreground">
								{shipmentStatus === ShipmentStatus.CARRIER_ASSIGNED ||
								shipmentStatus === ShipmentStatus.COST_DECLARED ||
								shipmentStatus === ShipmentStatus.EXECUTED ||
								shipmentStatus === ShipmentStatus.COMPLETED
									? t(
											'breakdown_tooltip.agreed_quote_price.label',
											'Agreed quote price',
										)
									: t('breakdown_tooltip.target_price.label', 'Target price')}
							</p>
							<span>{targetPrice}</span>
						</div>
						<div className="flex justify-between text-sm text-muted-foreground">
							<p>
								{t('pages.create_shipment.summary_section.fee.label', 'Fee')}
							</p>
							<span>
								<>
									{percentageValue && `(${percentageValue}%) `}
									{feeValue}
								</>
							</span>
						</div>

						<Separator className="my-2" />

						<div className="flex items-center justify-between">
							<p className="font-bold">
								{t(
									'pages.create_shipment.summary_section.total_price.label',
									'Total price',
								)}
							</p>
							<span className="font-bold">{totalPrice}</span>
						</div>
					</div>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
};

export default BreakDownTooltip;
