import { CurrencyType, type ShipmentListDTO } from '@uturn/api/v1';
import { DraftedShipments as DraftedShipmentsEmptyState } from '@uturn/design-tokens/empty-states';
import { EmptyState } from '@uturn/ui';
import { Button } from '@uturn/ui-kit';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
	RequirementBadges,
	ShipmentPage,
} from '@uturn/portal/modules/shipments/components';
import { formatPrice } from '@uturn/portal/utils';

function DraftedShipmentEmptyState() {
	const { t } = useTranslation();
	return (
		<EmptyState>
			<DraftedShipmentsEmptyState className="md:h-80" />
			<h2 className="text-secondary-700 text-2xl font-medium leading-7">
				{t('pages.shipments.drafted.emptyState.title', 'To the drawing board!')}
			</h2>
			<p className="text-secondary-600 text-lg leading-6">
				{t(
					'pages.shipments.drafted.emptyState.description',
					'Here your drafts will be stored. You can publish them to the market or keep them as templates to use for future shipments.',
				)}
			</p>
			<div>
				<Link to="/shipments/add">
					<Button>{t('pages.shipments.actions.start', 'Get started')}</Button>
				</Link>
			</div>
		</EmptyState>
	);
}

export function DraftedShipments() {
	const { t } = useTranslation();

	const shipmentDetails = (shipment: ShipmentListDTO) => [
		{
			label: t('pages.shipments.details.reference'),
			value: shipment.shipperReference,
			visibleOnMultiSelect: true,
			enableCopyToClipboard: true,
			hideOnCompact: true,
		},
		{
			label: t('pages.shipments.details.containerNumber'),
			value: shipment.containerNumber,
			visibleOnMultiSelect: true,
			enableCopyToClipboard: true,
		},
		{
			label: t('pages.shipments.details.price'),
			value: formatPrice(
				shipment.priceIncludingShipmentFee?.quantity ?? 0,
				shipment.priceIncludingShipmentFee?.currencyCode ?? CurrencyType.EUR,
			),
			visibleOnMultiSelect: true,
			hasInfoIcon: true,
		},
		{
			label: t('pages.shipments.details.details', 'Details'),
			value: (shipment.requirements || shipment.shipmentUnitDescription) && (
				<RequirementBadges
					requirements={[
						shipment.shipmentUnitDescription ?? '',
						...shipment.requirements,
					]}
				/>
			),
		},
	];

	return (
		<>
			<Helmet
				title={t('pages.shipments.drafted.title', 'Drafted shipments')!}
			/>
			<ShipmentPage
				enableGroupFilter={true}
				EmptyState={DraftedShipmentEmptyState}
				details={shipmentDetails}
			/>
		</>
	);
}
