import type { TFunction } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
	Outlet,
	useLocation,
	useMatch,
	useSearchParams,
} from 'react-router-dom';
import { EventsHistorySheet } from '../components/shipments-sheet';
import { ShipmentGroupDetailContext } from '../providers/shipment-group-detail';
import { ShipmentViewProvider } from '../providers/shipment-view';
import { Page } from '@uturn/portal/components/page';
import type { TabButton, TabLink } from '@uturn/portal/components/page/tabs';
import useShipmentViews, {
	ShipmentView,
} from '@uturn/portal/hooks/shipments/use-shipment-view';
import {
	CancelShipmentsException,
	ShipmentsCancelModal,
	ShipmentsExportModal,
} from '@uturn/portal/modules/shipments/components';
import { useShipmentsSearchStore } from '@uturn/portal/store/shipments/shipments-search';
import {
	type DefaultFilters,
	type StatusTab,
	defaultStatusFilters,
} from '@uturn/portal/types/shipments';

const pages = (
	t: TFunction<'translation', undefined>,
): (TabLink & {
	navTab: StatusTab;
	defaultFilters: DefaultFilters;
})[] => [
	{
		title: t('general.shipment-status.published', 'Published')!,
		to: '',
		navTab: 'published',
		defaultFilters: defaultStatusFilters(t, 'published'),
	},
	{
		title: t('general.shipment-status.assigned', 'Assigned')!,
		to: './assigned',
		navTab: 'assigned',
		defaultFilters: defaultStatusFilters(t, 'assigned'),
	},
	{
		title: t('general.shipment-status.drafted', 'Drafted')!,
		to: './drafted',
		navTab: 'drafted',
		defaultFilters: defaultStatusFilters(t, 'drafted'),
	},
	{
		title: t('general.shipment-status.archived', 'Archived')!,
		to: './archived',
		navTab: 'archived',
		defaultFilters: defaultStatusFilters(t, 'archived'),
	},
];

export function ShipmentsGroupDetailLayout() {
	const { t } = useTranslation();
	const views = useShipmentViews();

	const { groupId, groupName, setGroupId } = useContext(
		ShipmentGroupDetailContext,
	);
	const { totalShipments, activeStatusTab, setActiveStatusTab } =
		useShipmentsSearchStore();

	const match = useMatch('/shipments/groups/:groupId/*');

	useEffect(() => {
		const { groupId: group } = match!.params;

		setGroupId(group!);

		return () => {
			setGroupId('');
		};
	}, []);

	const { pathname } = useLocation();
	const [searchParams] = useSearchParams();

	const [shipmentView, setView] = useState(
		ShipmentView[
			(localStorage.getItem('user.settings.shipments.overview.view_mode') ??
				'Comfortable') as keyof typeof ShipmentView
		],
	);
	const basePathName = `/shipments/groups/${groupId}`;

	const handleCancelShipmentsExceptions = ():
		| CancelShipmentsException
		| undefined => {
		if (pathname.includes('/archived')) {
			return CancelShipmentsException.ARCHIVED;
		}
		if (totalShipments === 0) {
			return CancelShipmentsException.NO_SHIPMENTS;
		}
		return undefined;
	};

	const tabs = pages(t).map((item) => {
		const itemTo = basePathName + item.to.replace(/^\./, '');
		const active = pathname === itemTo;
		if (active && activeStatusTab !== item.navTab) {
			setActiveStatusTab(item.navTab);
		}
		return {
			...item,
			active,
		};
	});

	const rightTabs: TabButton[] = views.map((view) => {
		return {
			title: view.title,
			onClick: () => {
				localStorage.setItem(
					'user.settings.shipments.overview.view_mode',
					view.value,
				);
				setView(view.value);
			},
			active: shipmentView === view.value,
		};
	});

	const [openEvents, setOpenEvents] = useState(false);
	const [openExport, setOpenExport] = useState(false);
	const [openCancel, setOpenCancel] = useState(false);

	return (
		<>
			<Helmet title={groupName} />
			<Page
				title={groupName}
				backButton={{
					label: 'Shipment groups',
					href: '/shipments/groups',
				}}
				secondaryAction={{
					label: t('pages.shippers.actions.events', 'Events'),
					onClick: () => setOpenEvents(true),
				}}
				contextActions={shipmentView !== ShipmentView.Table ? [
					{
						label: t('pages.shippers.actions.export', 'Export'),
						onClick: () => setOpenExport(true),
					},
					{
						label: t('pages.shippers.actions.cancel', 'Cancel'),
						onClick: () => setOpenCancel(true),
						renderCondition:
							!handleCancelShipmentsExceptions() ||
							handleCancelShipmentsExceptions() ===
								CancelShipmentsException.NO_SHIPMENTS,
					},
				] : undefined}
				tabsStart={tabs}
				tabsEnd={rightTabs}
			>
				<EventsHistorySheet
					openSheet={openEvents}
					setOpenSheet={setOpenEvents}
				/>
				<ShipmentsExportModal
					exportFilters={{
						...tabs.filter((page) => page.to === pathname)[0]?.defaultFilters,
						...Object.fromEntries(searchParams),
					}}
					isOpenModal={openExport}
					setOpenModal={setOpenExport}
					totalShipments={totalShipments}
				/>
				{(!handleCancelShipmentsExceptions() ||
					handleCancelShipmentsExceptions() ===
						CancelShipmentsException.NO_SHIPMENTS) && (
					<ShipmentsCancelModal
						exportFilters={{
							...tabs.filter((page) => page.to === pathname)[0]?.defaultFilters,
							...Object.fromEntries(searchParams),
						}}
						statuses={
							tabs.filter((page) => page.to === pathname)[0]?.defaultFilters[
								'filter.statuses'
							] ?? []
						}
						isOpenModal={openCancel}
						setOpenModal={setOpenCancel}
					/>
				)}
				<ShipmentViewProvider view={shipmentView}>
					<Outlet />
				</ShipmentViewProvider>
			</Page>
		</>
	);
}
