import { faGear } from '@fortawesome/pro-regular-svg-icons';
import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	ConditionType,
	WeightUnit,
	useFetchOrganizationById,
	useFetchUturnUserById,
} from '@uturn/api/v1';
import {
	Button,
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Icon,
	RadioGroup,
	RadioGroupItem,
	sonner,
} from '@uturn/ui-kit';
import type { AxiosError } from 'axios';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useContext, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { EditDefaultValuesModal } from '../../components';
import {
	shouldUpdate,
	transportTypeName,
	typeOfShipmentDescription,
	typeOfShipmentIcon,
	typeOfShipmentTitle,
} from '../../utils';
import { MetaDataContext } from '@uturn/portal/context';
import { useAddShipmentStore } from '@uturn/portal/store/shipments/add-shipment';
import {
	ContainerStatus,
	TransportTypeCode,
	TypeOfShipment,
} from '@uturn/portal/types/shipment';
import { getApiErrorMessage } from '@uturn/portal/utils';
import { z } from '@uturn/portal/zod';

const FEATURE_FLAG_KEY = 'shipment_bulk_create';

type FormOptionsType<T> = {
	id: string;
	value: T;
	icon?: IconDefinition;
	label: string;
	description?: string;
	hidden?: boolean;
};

function FormRadioGroupHorizontalItem<T>({
	id,
	value,
	label,
	hidden = false,
}: FormOptionsType<T>) {
	if (hidden) return null;
	return (
		<FormItem>
			<FormControl>
				<FormLabel
					htmlFor={id}
					className={twMerge(
						'cursor-pointer w-full py-3 flex justify-center items-center gap-2 rounded-md border-2 border-muted bg-popover',
						'hover:bg-accent hover:text-accent-foreground',
						'peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary',
						'peer-data-[state=checked]:text-primary [&:has([data-state=checked])]:text-primary',
					)}
				>
					<RadioGroupItem
						className={twMerge(
							'peer border-default',
							'peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary',
							'peer-data-[state=checked]:bg-primary [&:has([data-state=checked])]:bg-primary',
							'peer-data-[state=checked]:text-white [&:has([data-state=checked])]:text-white',
						)}
						id={id}
						value={typeof value === 'boolean' ? id : String(value)}
					/>
					{label}
				</FormLabel>
			</FormControl>
		</FormItem>
	);
}

function FormRadioCardGroupHorizontalItem<T>({
	id,
	value,
	icon,
	label,
	description,
	hidden = false,
}: FormOptionsType<T>) {
	if (hidden || !icon || !description) return null;
	return (
		<FormItem>
			<FormControl>
				<FormLabel
					htmlFor={id}
					className={twMerge(
						'flex flex-col items-center h-full p-6 cursor-pointer rounded-md border-2 border-muted bg-popover',
						'hover:bg-accent hover:text-accent-foreground',
						'peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary',
						'peer-data-[state=checked]:text-primary [&:has([data-state=checked])]:text-primary',
					)}
				>
					<div className="flex flex-1 flex-col gap-6">
						<Icon size="2xl" icon={icon} />
						<div className="flex-1 space-y-2 text-center">
							<h3 className="text-lg font-semibold">{label}</h3>
							<p className="text-muted-foreground text-sm">{description}</p>
						</div>
					</div>
					<RadioGroupItem
						className={twMerge(
							'peer border-default mt-4',
							'peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary',
							'peer-data-[state=checked]:border-4 [&:has([data-state=checked])]:border-4',
							'peer-data-[state=checked]:bg-primary [&:has([data-state=checked])]:bg-primary',
							'peer-data-[state=checked]:text-white [&:has([data-state=checked])]:text-white',
						)}
						id={id}
						value={typeof value === 'boolean' ? id : String(value)}
					/>
				</FormLabel>
			</FormControl>
		</FormItem>
	);
}

export function AddShipmentForm() {
	const { t } = useTranslation();
	const { shipmentMetaData, metadata } = useContext(MetaDataContext);
	const navigate = useNavigate();
	const featureFlagEnabled = useFeatureFlagEnabled(FEATURE_FLAG_KEY);

	const {
		initData,
		orgDefaultValues,
		currencyCode,
		setManualCreateInit,
		setDefaultValues,
		setOrgDefaultValues,
		setCurrencyCode,
	} = useAddShipmentStore();

	const orgId = metadata?.user?.organisation?.id;

	const { isLoading: isOrgLoading } = useFetchOrganizationById(orgId ?? -1, {
		query: {
			enabled: !!orgId,
			select: (data) => data.data,
			onSuccess: (data) => {
				const { invoiceRequired, shipmentCondition } = data.settings ?? {};

				setOrgDefaultValues({
					invoiceRequired,
					conditions: shipmentCondition
						? ConditionType[shipmentCondition]
						: undefined,
				});

				const { currency } = data.financialDetails ?? {};

				if (currency) {
					setCurrencyCode(currency);
				}
			},
			onError: (error: AxiosError | any) => {
				sonner.error(
					t(
						'pages.addshipment.flow.org.api-error.title',
						'Could not load organization settings',
					),
					{
						description: getApiErrorMessage(error),
					},
				);
			},
		},
	});

	const userId = metadata?.user?.id;

	const { isLoading: isUserLoading, data: user } = useFetchUturnUserById(
		userId ?? -1,
		{
			query: {
				enabled: !!userId,
				select: (data) => data.data,
				onSuccess: (data) => {
					const { fixedPrice, shippingLineId, requiresVat, containerStatus } =
						data.settings ?? {};

					const defaultShippingLine =
						shippingLineId && shippingLineId > -1
							? shipmentMetaData?.shippingLines?.find(
									(item) => item.id === shippingLineId,
								)
							: undefined;

					setDefaultValues({
						fixedPrice,
						dutiesPaid: requiresVat,
						containerStatus: containerStatus
							? ContainerStatus[containerStatus]
							: undefined,
						grossWeight: {
							quantity: 0,
							unit: WeightUnit.kilogram,
						},
						shippingLineId: defaultShippingLine?.id,
						shippingLine: defaultShippingLine,
					});
				},
				onError: (error: AxiosError | any) => {
					sonner.error(
						t(
							'pages.addshipment.flow.user.api-error.title',
							'Could not load user settings',
						),
						{
							description: getApiErrorMessage(error),
						},
					);
				},
			},
		},
	);

	const [openEditDefaultValuesModal, setOpenEditDefaultValuesModal] =
		useState(false);

	const formSchema = z.object({
		transportType: z.nativeEnum(TransportTypeCode),
		typeOfShipment: z.nativeEnum(TypeOfShipment),
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			transportType: user?.settings.transportType
				? TransportTypeCode[user.settings.transportType]
				: undefined,
		},
	});

	const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (formData) => {
		setManualCreateInit(
			formData.transportType,
			formData.typeOfShipment,
			initData,
			orgDefaultValues,
			currencyCode,
		);

		if (formData.typeOfShipment === TypeOfShipment.MULTI) {
			navigate({
				pathname: '/shipments/add/bulk',
				search: createSearchParams({
					transportType: formData.transportType,
				}).toString(),
			});
			return;
		}

		navigate('/shipments/add/manual');
	};

	const transportTypeOptions: FormOptionsType<TransportTypeCode>[] = [
		{
			id: TransportTypeCode.IMPORT,
			value: TransportTypeCode.IMPORT,
			label: transportTypeName(t)[TransportTypeCode.IMPORT],
		},
		{
			id: TransportTypeCode.EXPORT,
			value: TransportTypeCode.EXPORT,
			label: transportTypeName(t)[TransportTypeCode.EXPORT],
		},
		{
			id: TransportTypeCode.SHUNT,
			value: TransportTypeCode.SHUNT,
			label: transportTypeName(t)[TransportTypeCode.SHUNT],
		},
	];

	const typeOfShipmentOptions: FormOptionsType<TypeOfShipment>[] = [
		{
			id: TypeOfShipment.SINGLE,
			value: TypeOfShipment.SINGLE,
			icon: typeOfShipmentIcon[TypeOfShipment.SINGLE],
			label: typeOfShipmentTitle(t)[TypeOfShipment.SINGLE],
			description: typeOfShipmentDescription(t)[TypeOfShipment.SINGLE],
		},
		{
			id: TypeOfShipment.GROUP,
			value: TypeOfShipment.GROUP,
			icon: typeOfShipmentIcon[TypeOfShipment.GROUP],
			label: typeOfShipmentTitle(t)[TypeOfShipment.GROUP],
			description: typeOfShipmentDescription(t)[TypeOfShipment.GROUP],
		},
		{
			id: TypeOfShipment.MULTI,
			value: TypeOfShipment.MULTI,
			icon: typeOfShipmentIcon[TypeOfShipment.MULTI],
			label: typeOfShipmentTitle(t)[TypeOfShipment.MULTI],
			description: typeOfShipmentDescription(t)[TypeOfShipment.MULTI],
			hidden: !featureFlagEnabled,
		},
	];

	return (
		<>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)}>
					<Card>
						<CardHeader>
							<CardTitle className="flex items-center justify-between gap-2">
								<span className="flex-1">
									<Button
										variant="outline"
										type="button"
										className="flex gap-2"
										onClick={() => setOpenEditDefaultValuesModal(true)}
									>
										<Icon icon={faGear} />
										{t(
											'pages.addshipment.flow.action.defaults',
											'Change defaults',
										)}
									</Button>
								</span>
								<span className="flex-none">
									{t('pages.addshipment.flow.title', 'Create shipment')}
								</span>
								<span className="flex-1"></span>
							</CardTitle>
						</CardHeader>
						<CardContent className="space-y-6">
							<div className="space-y-4">
								<p className="text-muted-foreground text-center text-sm">
									{t(
										'pages.addshipment.flow.transport-type.description',
										'Select a transport type to create a new shipment.',
									)}
								</p>
								<FormField
									control={form.control}
									name="transportType"
									render={({ field }) => (
										<FormItem>
											<FormControl>
												<RadioGroup
													value={field.value}
													onValueChange={field.onChange}
													className="mt-0 grid grid-cols-3 gap-2 pt-0"
												>
													{transportTypeOptions.map((item) => {
														return (
															<FormRadioGroupHorizontalItem
																key={item.id}
																{...item}
															/>
														);
													})}
												</RadioGroup>
											</FormControl>
											<FormMessage className="text-center" />
										</FormItem>
									)}
								/>
							</div>
							<div className="space-y-4">
								<p className="text-muted-foreground text-center text-sm">
									{t(
										'pages.addshipment.flow.type-of-shipment.description',
										'How do you want to create your shipment(s)?',
									)}
								</p>
								<FormField
									control={form.control}
									name="typeOfShipment"
									render={({ field }) => (
										<FormItem>
											<FormControl>
												<RadioGroup
													value={field.value}
													onValueChange={field.onChange}
													className={twMerge(
														'grid gap-2 mt-0 pt-0',
														featureFlagEnabled ? 'grid-cols-3' : 'grid-cols-2',
													)}
												>
													{typeOfShipmentOptions.map((item) => {
														return (
															<FormRadioCardGroupHorizontalItem
																key={item.id}
																{...item}
															/>
														);
													})}
												</RadioGroup>
											</FormControl>
											<FormMessage className="text-center" />
										</FormItem>
									)}
								/>
							</div>
						</CardContent>
						<CardFooter className="flex justify-center">
							<Button
								type="submit"
								variant="default"
								className="w-48"
								disabled={isOrgLoading || isUserLoading}
							>
								{t('pages.addshipment.flow.submit', 'Continue')}
							</Button>
						</CardFooter>
					</Card>
				</form>
			</Form>
			<EditDefaultValuesModal
				openModal={openEditDefaultValuesModal}
				setOpenModal={setOpenEditDefaultValuesModal}
				onSuccess={(data) => {
					const transportTypeString = data.settings.transportType;
					if (transportTypeString) {
						form.setValue(
							'transportType',
							TransportTypeCode[transportTypeString],
							shouldUpdate,
						);
					}
				}}
			/>
		</>
	);
}
